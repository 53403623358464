import "./logo-animation.scss"
import React, { useState, useEffect } from "react"

const LogoAnimation = () => {
  const [animation, setAnimation] = useState(true)
  const [resize, setResize] = useState(false)
  const [skew, setSkew] = useState(false)

	useEffect(() => {
    setTimeout(() => {
      setTimeout(() => {
        setResize(true);
      }, 500);

      setTimeout(() => {
        setSkew(true);
      }, 1000);
    }, 100);
	}, [animation])

  const replayAnimation = () => {
    setResize(false)
    setSkew(false)
    setAnimation(!animation)
  }

  return (
    <div className="logo-animation-container">
        <div className="logo-animation-wrapper">
          <div className={`logo-animation-box${resize ? ' opacity' : ''}`} onClick={replayAnimation}>
            <div className={`logo-animation-bar-one${resize ? ' grow' : ''}${skew ? ' skew' : ''}`}></div>
            <div className={`logo-animation-bar-two${resize ? ' grow' : ''}${skew ? ' skew' : ''}`}></div>
            <div className={`logo-animation-bar-three${resize ? ' grow' : ''}${skew ? ' skew' : ''}`}></div>
            <div className={`logo-animation-bar-four${resize ? ' grow' : ''}${skew ? ' skew' : ''}`}></div>
            <div className={`logo-animation-bar-five${resize ? ' grow' : ''}${skew ? ' skew' : ''}`}></div>
            <div className={`logo-animation-bar-six${resize ? ' grow' : ''}${skew ? ' skew' : ''}`}></div>
            <div className={`logo-animation-bar-seven${resize ? ' grow' : ''}${skew ? ' skew' : ''}`}></div>
            <div className={`logo-animation-bar-eight${resize ? ' grow' : ''}${skew ? ' skew' : ''}`}></div>
            <div className={`logo-animation-background-bar-one${resize ? ' grow' : ''}`}></div>
            <div className={`logo-animation-background-bar-two${resize ? ' grow' : ''}`}></div>
            <div className={`logo-animation-background-bar-three${resize ? ' grow' : ''}`}></div>
            <div className={`logo-animation-background-bar-four${resize ? ' grow' : ''}`}></div>
            <div className={`logo-animation-background-bar-five${resize ? ' grow' : ''}`}></div>
            <div className={`logo-animation-background-bar-six${resize ? ' grow' : ''}`}></div>
            <div className={`logo-animation-background-bar-seven${resize ? ' grow' : ''}`}></div>
          </div>
        </div>
      </div>
  )
}
 
export default LogoAnimation

 