import React from 'react';
import styled from 'styled-components';
import { FeatureHeader } from '../StyleGuide/Atoms';
import * as styles from '../StyleGuide/Styles/Stylesheet.js';
import * as Organism from '../StyleGuide/Organisms';

const CalculatorContainer = styled.div`
  display: block;
`

const CalculatorButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${styles.color_gry_1};
  padding: ${styles.spacing_standard} 0;
`

class ConversionCalculator extends React.Component {

  constructor() {
    super();
    this.state = {
      calculator: false
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  openCalculator() {
    this.setState({
      calculator: true
    })
  }

  closeCalculator() {
    this.setState({
      calculator: false
    })
  }

  render() {

    return (
      <CalculatorContainer>
        <FeatureHeader title="Conversion Calculator" text="Built for Plumb's, a product veterinarians use in practice. The interface displays the most commonly used conversions. It was built using styled components"> </FeatureHeader>
        <CalculatorButtonWrapper>
          <Organism.Calculator toggleCalulator={this.closeCalculator.bind(this)} closeCalculator={this.props.closeCalculator} />
        </CalculatorButtonWrapper>
      </CalculatorContainer>
    );
  }
}

export default ConversionCalculator;
