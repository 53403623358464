import "./grid.scss";
import React from "react";

export const Row = ({ xs, sm, md, lg, gutter, spacing, children }) => {
  return (
    <div
      className={`grid row
      ${spacing ? " spacing-" + spacing : ""}
      ${gutter ? " gutter-" + gutter : ""}
      ${xs ? " xs-" + xs : ""}
      ${sm ? " sm-" + sm : ""}
      ${md ? " md-" + md : ""}
      ${lg ? " lg-" + lg : ""}`}
    >
      {children}
    </div>
  );
};

export const Column = ({ xs, sm, md, lg, gutter, children }) => {
  return (
    <div
      className={`grid column
      ${xs ? " xs-" + xs : ""}
      ${sm ? " sm-" + sm : ""}
      ${md ? " md-" + md : ""}
      ${lg ? " lg-" + lg : ""}`}
    >
      {children}
    </div>
  );
};
