import "./app-header.scss"
import React, { useState, useEffect } from "react"
import {
  Link
} from "react-router-dom";
import WinnLogo from '../../../Media/Images/WinnLogo.svg';

const AppHeader = () =>{
  const [desktopDropdown, setDesktopDropdown] = useState(false)
  const [mobileDropdown, setMobileDropdown] = useState(false)

	useEffect(() => {
    const resizeListener = () => {
      if (window.innerWidth > 860) {
        setMobileDropdown(false)
      } else {
        setDesktopDropdown(false)
      }
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    }
	}, [])

  const desktopDropdownHandler = () => {
    setDesktopDropdown(!desktopDropdown)
  }

  const mobileDropdownHandler = () => {
    setMobileDropdown(!mobileDropdown)
  }

  const closeDropdowns = () => {
    setDesktopDropdown(false)
    setMobileDropdown(false)
  }

  const styles = {
		desktopDropdownHeight: {
			height: `144px`
		},
		mobileDropdownHeight: {
			right: `0`
		},
	}

  return (
    <div className="app-header">
      <div className="header-fixed">
        <div className="header-wrapper">
          <div className="header-logo-container">
            <Link className="header-logo-link" to="/">
              <img className="header-logo" src={WinnLogo} onClick={closeDropdowns} />
            </Link>
          </div>
          <div onClick={mobileDropdownHandler} className={`header-icon${mobileDropdown ? ' open' : ''}`}>
            <div className="header-button-text"><div className={`mobile-icon fa${mobileDropdown ? ' fa-close' : ' fa-bars'}`}></div></div>
            <div onClick={closeDropdowns} className="header-mobile-menu" style={mobileDropdown ? styles.mobileDropdownHeight : null}>
              <Link className="header-dropdown-link" to="/resume">Resume</Link>
              <Link className="header-dropdown-link" to="/style-guide">Style&nbsp;Guide</Link>
              <Link className="header-dropdown-link" to="/conversion-calculator">Conversion Calculator</Link>
              <Link className="header-dropdown-link" to="/piano">Piano</Link>
              <Link className="header-dropdown-link" to="/character-sheets">Character Sheets</Link>
            </div>
          </div>

          <div className="header-buttons">
            <Link className="header-link-clickable-area resume" to="/resume" onClick={closeDropdowns}></Link>
            <Link className="header-link-clickable-area style-guide" to="/style-guide" onClick={closeDropdowns}></Link>
            <div onClick={desktopDropdownHandler} className="header-dropdown-clickable-area"></div>
            <div className="header-links-container">
              <div className="header-button-link resume">
                <div className="header-button-text">Resume</div>
              </div>
              <div className="header-button-link style-guide">
                <div className="header-button-text">Style&nbsp;Guide</div>
              </div>
              <div className="header-dropdown-button">
                <div className="header-button-text">Projects</div>
                <div className={`header-dropdown-icon fa fa-chevron-down${desktopDropdown ? ' open' : ''}`}></div>
              </div>
            </div>
            <div onClick={closeDropdowns} className={`header-dropdown${desktopDropdown ? ' open' : ''}`} style={desktopDropdown ? styles.desktopDropdownHeight : null}>
              <Link className="header-dropdown-link" to="/conversion-calculator">Conversion Calculator</Link>
              <Link className="header-dropdown-link" to="/piano">Piano</Link>
              <Link className="header-dropdown-link" to="/character-sheets">Character Sheets</Link>
            </div>
          </div>
        </div>
        {desktopDropdown || mobileDropdown ? <div onClick={closeDropdowns} className="header-overlay">
        </div> : null}
      </div>
      <div className="header-faux-height"></div>
    </div>
  )
}
 
export default AppHeader

 