import "./image-zoom.scss"
import React, { useState } from "react";

const ImageZoom = ({image}) =>{
  const [zoom, setZoom] = useState(false)

  const zoomHandler = () => {
    setZoom(!zoom)
  }
  
  const styles = {
    image: {
        backgroundImage: `url(${image})`
    }
}

  return (
    <div className="image-zoom-container">
      <div className="image-block" style={styles.image} onClick={zoomHandler} ></div>
      { zoom ? <div className="image-zoomed-overlay" onClick={zoomHandler}>
        <div className="image-zoomed-wrapper">
          <img className="image-zoomed-block" src={image} />
        </div>
      </div> : null }
    </div>
  )
}
 
export default ImageZoom

 