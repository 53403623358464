import React from 'react';
import styled, { css } from 'styled-components';
import * as styles from '../Styles/Stylesheet.js';
import * as animations from '../Styles/Animations.js';

export const AnimationsContainer = styled.div`
  width: 100%;
  max-width: ${styles.width_mn};
  display: flex;
  flex-wrap: wrap;
`

export const AnimationsWrapper = styled.div`
  width: 16.66%;
  display: flex;
  flex-direction: column;
  padding: ${styles.spacing_standard_half};
  cursor: pointer;

  @media only screen and (max-width: ${styles.width_mn}) {
    width: 25%;
  }

  @media only screen and (max-width: ${styles.width_nrw}) {
    width: 50%;
  }

  @media only screen and (max-width: ${styles.width_sm}) {
    width: 100%;
  }
`

export const AnimationBlock = styled.div`
  display: flex;
  width: 100%;
  height: 140px;
  border-top: 1px solid ${styles.color_gry_2};
  border-left: 1px solid ${styles.color_gry_2};
  border-right: 1px solid ${styles.color_gry_2};
`

export const Animation = styled.div`
  display: flex;
  width: 100%;
  height: 140px;
  background-color: ${styles.color_primary};
  z-index: 1;

  ${props => props.animation && css `
    ${props => props.animation};
  `}

  ${props => props.hide && css `
    display: none;
  `}
`

export const AnimationInformation = styled.div`
  width: 100%;
  padding: ${styles.spacing_standard_half};
  border: 1px solid ${styles.color_gry_2};
`

export const AnimationName = styled.div`
  width: 100%;
  font-family: ${styles.font_sans};
  line-height: 1;
  font-size: 12px;
  font-weight: ${styles.font_normal};
`

export class Animations extends React.Component {


    constructor() {
      super();
      this.state = {
        animations: {
          bounce_in_forward: true,
          bounce_in_back: true,
          fade_in: true,
          fade_out: true,
          fade_out_back: true,
          fade_in_back: true,
          fade_out_forward: true,
          fade_in_top: true,
          fade_out_top: true,
          fade_in_bottom: true,
          fade_out_bottom: true,
          fade_in_forward: true,
          fade_in_right: true,
          fade_out_right: true,
          fade_in_left: true,
          fade_out_left: true,
          slide_in_right: true,
          slide_out_right: true,
          slide_in_left: true,
          slide_out_left: true,
          shake: true,
          pulsate_forward: true,
          pulsate_back: true,
          heartbeat: true,
        },
      };
    }

  replayAnimation(animation) {
    const activeAnimations = this.state.animations

    Object.keys(activeAnimations).forEach((value) => {
      activeAnimations[value] = true;
    });
    activeAnimations[animation] = false

    this.setState({
      animations: activeAnimations
    }, () => {
      setTimeout(() => {
        const activeAnimations = this.state.animations

        Object.keys(activeAnimations).forEach((value) => {
          activeAnimations[value] = true;
        });

        this.setState({
          animations: activeAnimations
        })
      }, 100);
    })
  }

  render() {
    return (
      <AnimationsContainer>
        <AnimationsWrapper onClick={() => this.replayAnimation('bounce_in_forward')}>
          <AnimationBlock>
            <Animation animation={animations.bounce_in_forward} hide={!this.state.animations.bounce_in_forward}></Animation>
          </AnimationBlock>
          <AnimationInformation>
            <AnimationName>bounce_in_forward</AnimationName>
          </AnimationInformation>
        </AnimationsWrapper>

        <AnimationsWrapper onClick={() => this.replayAnimation('bounce_in_back')}>
          <AnimationBlock>
            <Animation animation={animations.bounce_in_back} hide={!this.state.animations.bounce_in_back}></Animation>
          </AnimationBlock>
          <AnimationInformation>
            <AnimationName>bounce_in_back</AnimationName>
          </AnimationInformation>
        </AnimationsWrapper>

        <AnimationsWrapper onClick={() => this.replayAnimation('fade_in')}>
          <AnimationBlock>
            <Animation animation={animations.fade_in} hide={!this.state.animations.fade_in}></Animation>
          </AnimationBlock>
          <AnimationInformation>
            <AnimationName>fade_in</AnimationName>
          </AnimationInformation>
        </AnimationsWrapper>

        <AnimationsWrapper onClick={() => this.replayAnimation('fade_out')}>
          <AnimationBlock>
            <Animation animation={animations.fade_out} hide={!this.state.animations.fade_out}></Animation>
          </AnimationBlock>
          <AnimationInformation>
            <AnimationName>fade_out</AnimationName>
          </AnimationInformation>
        </AnimationsWrapper>

        <AnimationsWrapper onClick={() => this.replayAnimation('fade_in_forward')}>
          <AnimationBlock>
            <Animation animation={animations.fade_in_forward} hide={!this.state.animations.fade_in_forward}></Animation>
          </AnimationBlock>
          <AnimationInformation>
            <AnimationName>fade_in_forward</AnimationName>
          </AnimationInformation>
        </AnimationsWrapper>

        <AnimationsWrapper onClick={() => this.replayAnimation('fade_out_forward')}>
          <AnimationBlock>
            <Animation animation={animations.fade_out_forward} hide={!this.state.animations.fade_out_forward}></Animation>
          </AnimationBlock>
          <AnimationInformation>
            <AnimationName>fade_out_forward</AnimationName>
          </AnimationInformation>
        </AnimationsWrapper>

        <AnimationsWrapper onClick={() => this.replayAnimation('fade_in_back')}>
          <AnimationBlock>
            <Animation animation={animations.fade_in_back} hide={!this.state.animations.fade_in_back}></Animation>
          </AnimationBlock>
          <AnimationInformation>
            <AnimationName>fade_in_back</AnimationName>
          </AnimationInformation>
        </AnimationsWrapper>

        <AnimationsWrapper onClick={() => this.replayAnimation('fade_out_back')}>
          <AnimationBlock>
            <Animation animation={animations.fade_out_back} hide={!this.state.animations.fade_out_back}></Animation>
          </AnimationBlock>
          <AnimationInformation>
            <AnimationName>fade_out_back</AnimationName>
          </AnimationInformation>
        </AnimationsWrapper>

        <AnimationsWrapper onClick={() => this.replayAnimation('fade_in_top')}>
          <AnimationBlock>
            <Animation animation={animations.fade_in_top} hide={!this.state.animations.fade_in_top}></Animation>
          </AnimationBlock>
          <AnimationInformation>
            <AnimationName>fade_in_top</AnimationName>
          </AnimationInformation>
        </AnimationsWrapper>

        <AnimationsWrapper onClick={() => this.replayAnimation('fade_out_top')}>
          <AnimationBlock>
            <Animation animation={animations.fade_out_top} hide={!this.state.animations.fade_out_top}></Animation>
          </AnimationBlock>
          <AnimationInformation>
            <AnimationName>fade_out_top</AnimationName>
          </AnimationInformation>
        </AnimationsWrapper>

        <AnimationsWrapper onClick={() => this.replayAnimation('fade_in_bottom')}>
          <AnimationBlock>
            <Animation animation={animations.fade_in_bottom} hide={!this.state.animations.fade_in_bottom}></Animation>
          </AnimationBlock>
          <AnimationInformation>
            <AnimationName>fade_in_bottom</AnimationName>
          </AnimationInformation>
        </AnimationsWrapper>

        <AnimationsWrapper onClick={() => this.replayAnimation('fade_out_bottom')}>
          <AnimationBlock>
            <Animation animation={animations.fade_out_bottom} hide={!this.state.animations.fade_out_bottom}></Animation>
          </AnimationBlock>
          <AnimationInformation>
            <AnimationName>fade_out_bottom</AnimationName>
          </AnimationInformation>
        </AnimationsWrapper>

        <AnimationsWrapper onClick={() => this.replayAnimation('fade_in_right')}>
          <AnimationBlock>
            <Animation animation={animations.fade_in_right} hide={!this.state.animations.fade_in_right}></Animation>
          </AnimationBlock>
          <AnimationInformation>
            <AnimationName>fade_in_right</AnimationName>
          </AnimationInformation>
        </AnimationsWrapper>

        <AnimationsWrapper onClick={() => this.replayAnimation('fade_out_right')}>
          <AnimationBlock>
            <Animation animation={animations.fade_out_right} hide={!this.state.animations.fade_out_right}></Animation>
          </AnimationBlock>
          <AnimationInformation>
            <AnimationName>fade_out_right</AnimationName>
          </AnimationInformation>
        </AnimationsWrapper>

        <AnimationsWrapper onClick={() => this.replayAnimation('fade_in_left')}>
          <AnimationBlock>
            <Animation animation={animations.fade_in_left} hide={!this.state.animations.fade_in_left}></Animation>
          </AnimationBlock>
          <AnimationInformation>
            <AnimationName>fade_in_left</AnimationName>
          </AnimationInformation>
        </AnimationsWrapper>

        <AnimationsWrapper onClick={() => this.replayAnimation('fade_out_left')}>
          <AnimationBlock>
            <Animation animation={animations.fade_out_left} hide={!this.state.animations.fade_out_left}></Animation>
          </AnimationBlock>
          <AnimationInformation>
            <AnimationName>fade_out_left</AnimationName>
          </AnimationInformation>
        </AnimationsWrapper>

        <AnimationsWrapper onClick={() => this.replayAnimation('slide_in_right')}>
          <AnimationBlock>
            <Animation animation={animations.slide_in_right} hide={!this.state.animations.slide_in_right}></Animation>
          </AnimationBlock>
          <AnimationInformation>
            <AnimationName>slide_in_right</AnimationName>
          </AnimationInformation>
        </AnimationsWrapper>

        <AnimationsWrapper onClick={() => this.replayAnimation('slide_out_right')}>
          <AnimationBlock>
            <Animation animation={animations.slide_out_right} hide={!this.state.animations.slide_out_right}></Animation>
          </AnimationBlock>
          <AnimationInformation>
            <AnimationName>slide_out_right</AnimationName>
          </AnimationInformation>
        </AnimationsWrapper>

        <AnimationsWrapper onClick={() => this.replayAnimation('slide_in_left')}>
          <AnimationBlock>
            <Animation animation={animations.slide_in_left} hide={!this.state.animations.slide_in_left}></Animation>
          </AnimationBlock>
          <AnimationInformation>
            <AnimationName>slide_in_left</AnimationName>
          </AnimationInformation>
        </AnimationsWrapper>

        <AnimationsWrapper onClick={() => this.replayAnimation('slide_out_left')}>
          <AnimationBlock>
            <Animation animation={animations.slide_out_left} hide={!this.state.animations.slide_out_left}></Animation>
          </AnimationBlock>
          <AnimationInformation>
            <AnimationName>slide_out_left</AnimationName>
          </AnimationInformation>
        </AnimationsWrapper>

        <AnimationsWrapper onClick={() => this.replayAnimation('shake')}>
          <AnimationBlock>
            <Animation animation={animations.shake} hide={!this.state.animations.shake}></Animation>
          </AnimationBlock>
          <AnimationInformation>
            <AnimationName>shake</AnimationName>
          </AnimationInformation>
        </AnimationsWrapper>

        <AnimationsWrapper onClick={() => this.replayAnimation('pulsate_forward')}>
          <AnimationBlock>
            <Animation animation={animations.pulsate_forward} hide={!this.state.animations.pulsate_forward}></Animation>
          </AnimationBlock>
          <AnimationInformation>
            <AnimationName>pulsate_forward</AnimationName>
          </AnimationInformation>
        </AnimationsWrapper>

        <AnimationsWrapper onClick={() => this.replayAnimation('pulsate_back')}>
          <AnimationBlock>
            <Animation animation={animations.pulsate_back} hide={!this.state.animations.pulsate_back}></Animation>
          </AnimationBlock>
          <AnimationInformation>
            <AnimationName>pulsate_back</AnimationName>
          </AnimationInformation>
        </AnimationsWrapper>

        <AnimationsWrapper onClick={() => this.replayAnimation('heartbeat')}>
          <AnimationBlock>
            <Animation animation={animations.heartbeat} hide={!this.state.animations.heartbeat}></Animation>
          </AnimationBlock>
          <AnimationInformation>
            <AnimationName>heartbeat</AnimationName>
          </AnimationInformation>
        </AnimationsWrapper>

      </AnimationsContainer>
    );
  }
}
