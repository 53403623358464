import React from 'react';
import styled, { css } from 'styled-components';
import * as styles from '../Styles/Stylesheet.js';

export const CheckBoxContainer = styled.div`
  width: 100%;
  padding: 0 0 ${styles.spacing_standard_half} 0;
`

export const CheckBoxOutline = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 21px;
  height: 20px;
  background-color: ${styles.color_white};
  border: 2px solid ${styles.color_gry_2};
  transition: all 100ms;

  ${props => props.checked && css `
    border: 2px solid ${styles.color_accent}
  `}
`

export const CheckBoxSquare = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${styles.color_white};
  transition: all 100ms;

  ${props => props.checked && css `
    background-color: ${styles.color_accent};
    animation: sizing 100ms;

    @keyframes sizing {
      0% {
        transform: scale(1.0);
      }
      80% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(1.0);
      }
    }
  `}
`

export const CheckBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  :hover ${CheckBoxOutline} {
    border: 2px solid ${styles.color_gry_3};
  }

  :hover ${CheckBoxSquare} {
    background-color: ${styles.color_gry_3};
  }

  ${props => props.checked && css `
    :hover ${CheckBoxOutline} {
      border: 2px solid ${styles.color_accent}
    }

    :hover ${CheckBoxSquare} {
      background-color: ${styles.color_accent};
    }
  `}

  ${props => props.inactive && css `
    cursor: not-allowed;

    :hover ${CheckBoxOutline} {
      border: 2px solid ${styles.color_gry_2};
    }

    :hover ${CheckBoxSquare} {
      background-color: ${styles.color_white};
    }
  `}
`

export const CheckBoxText = styled.div`
  width: 100%;
  padding: 0 12px;
  font-family: ${styles.font_sans};
  font-size: 16px;
  font-weight: ${styles.font_normal};
  color: ${styles.color_gry_6};
  line-height: 1.6;

  ${props => props.inactive && css `
    color: ${styles.color_gry_3};
  `}
`

export class CheckBox extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.checked
    };
  }

  toggleCheck() {
    const checkedState = this.state.checked

    this.setState({
      checked: !checkedState
    })
  }

  render() {
    return (
      <CheckBoxContainer>
        {this.props.inactive ?
          <CheckBoxWrapper inactive={this.props.inactive}>
            <CheckBoxOutline id={this.props.identification} checked={this.state.checked} inactive={this.props.inactive}>
              <CheckBoxSquare checked={this.state.checked}>
              </CheckBoxSquare>
            </CheckBoxOutline>
            <CheckBoxText inactive={this.props.inactive}>{this.props.text}</CheckBoxText>
          </CheckBoxWrapper>
          :
          <CheckBoxWrapper checked={this.state.checked} onClick={() => this.toggleCheck()}>
            <CheckBoxOutline id={this.props.identification} checked={this.state.checked}>
              <CheckBoxSquare checked={this.state.checked}>
              </CheckBoxSquare>
            </CheckBoxOutline>
            <CheckBoxText>{this.props.text}</CheckBoxText>
          </CheckBoxWrapper>}
      </CheckBoxContainer>
    );
  }
}
