import React from 'react';
import styled from 'styled-components';
import * as styles from '../Styles/Stylesheet.js';

const ImageZoomContainer = styled.div`
  position: relative;
  display: block;
  width: 100%;
  transition: all 250ms;
  cursor: zoom-in;

  :hover {
    box-shadow: ${styles.box_shadow};
    transition: all 250ms;
  }
`

const ImageBlock = styled.img`
  width: 100%;
`

const ImageZoomedOverlay = styled.div`
  width: 100%;
  height: 100vh;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: ${styles.overlay_color};
  transform: translate(-50%, -50%);
  z-index: 9999;
  cursor: zoom-out;
`

const ImageZoomedWrapper = styled.div`
  display: flex
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${styles.container_standard};
`

const ImageZoomedBlock = styled.img`
  max-width: 100%;
  max-height: calc(100vh - (2 * ${styles.container_standard}));
`


export class ImageZoom extends React.Component {

  constructor() {
    super();
    this.state = {
      zoom: false,
    };
  }

  toggleZoom() {
    const zoomState = this.state.zoom

    this.setState({
      zoom: !zoomState
    })
  }

  render() {

    return (
      <ImageZoomContainer>
        <ImageBlock src={this.props.image} onClick={() => {this.toggleZoom()}}></ImageBlock>
        { this.state.zoom ? <ImageZoomedOverlay onClick={() => {this.toggleZoom()}}>
          <ImageZoomedWrapper>
            <ImageZoomedBlock src={this.props.image}></ImageZoomedBlock>
          </ImageZoomedWrapper>
        </ImageZoomedOverlay> : null }
      </ImageZoomContainer>
    );
  }
}
