import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.div`
  width: 100%;
`

class Footer extends React.Component {

  render() {

    return (
      <FooterContainer>
      </FooterContainer>
    );
  }
}

export default Footer;
