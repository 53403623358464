import "./feature-header.scss"
import React from "react"
import {Header} from "../typography/Typography";

const FeatureHeader = ({title, text}) =>{

    return (
      <div className="feature-header-container">
        <Header align="center">
          {title}
        </Header>
        {text ?
        <div className="feature-header-text">
          {text}
        </div>
        : null}
      </div>
    )
}
 
export default FeatureHeader

 