import React from 'react';
import styled, { css } from 'styled-components'
import * as styles from '../Styles/Stylesheet.js'

// CLOSE BUTTON

export const CalculatorCloseButton = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  top: -10px;
  right: -10px;
  background-color: ${styles.color_white};
  color: ${styles.color_red};
  font-size: 28px;
  z-index: 1;
  transition: all 250ms;
  cursor: pointer;

  :hover {
    color: ${styles.color_red_dark};
    transform: scale(1.2);
  }
`

// CALCULATOR

export const CalculatorContainer = styled.div`
  width: 100%;
  max-width: 400px;
  min-width: 200px;
  padding: ${styles.spacing_standard};
`

export const CalculatorWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: ${styles.box_shadow};
  background-color: ${styles.color_white};
  padding: ${styles.spacing_standard};
`

// DISPLAYS

export const CalculatorDisplaysWrapper = styled.div`
  padding-bottom: ${styles.spacing_standard};

  & * {
    font-family: ${styles.font_helv};
  }
`

export const CalculatorDisplays = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const CalculatorDisplay = styled.div`
  position: relative;
  width: 100%;
  color: ${styles.color_gry_5};
  background: ${styles.color_white};
  border: 1px solid ${styles.color_gry_2};
  height: 80px;
  font-size: 22px;
  line-height: 1;
  text-align: right;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 ${styles.spacing_standard} 18px;
  text-transform: lowercase;
  transition: all 250ms;

  :hover {
    border: 1px solid ${styles.color_gry_3};
  }

  @media only screen and (max-width: ${styles.width_xs}) {
    font-size: 18px;
    height: 70px;
  }

  ${props => props.calculatorConverion && css `
    cursor: pointer;
    width: 50%;
  `}

  ${props => props.active && css `
    border: 1px solid ${styles.color_accent};

    :hover {
      border: 1px solid ${styles.color_accent};
    }
  `}
`

export const CalculatorConversionDisplay = styled.div`
  cursor: pointer;
  width: 50%;
`

// DISPLAY ELEMENTS

export const CalculatorDisplayInfo = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 12px;
  line-height: 1;
  font-weight: bold;

  ${props => props.uppercase && css `
    text-transform: uppercase;
  `}
`

export const CalculatorTriangle = styled.div`
  position: absolute;
  display: block;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0px 0px 0;
  border-color: transparent ${styles.color_accent} transparent transparent;
  transition: all 250ms;

  ${props => props.active && css `
    border-width: 0 10px 10px 0;
  `}
`

export const CalculatorTriangleWhiteRight = styled.div`
  position: absolute;
  display: none;
  z-index: 2;
  right: -6px;
  top: 36px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 0 8px 8px;
  border-color: transparent transparent transparent ${styles.color_white};

  ${props => props.active && css `
    display: block;
  `}
`

export const CalculatorTriangleGryRight = styled.div`
  position: absolute;
  display: none;
  z-index: 1;
  right: -8px;
  top: 36px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 0 8px 8px;
  border-color: transparent transparent transparent ${styles.color_accent};

  ${props => props.active && css `
    display: block;
  `}
`

export const CalculatorTriangleWhiteLeft = styled.div`
  position: absolute;
  display: none;
  z-index: 2;
  left: -6px;
  top: 36px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 8px 0;
  border-color: transparent ${styles.color_white} transparent transparent;

  ${props => props.active && css `
    display: block;
  `}
`

export const CalculatorTriangleGryLeft = styled.div`
  position: absolute;
  display: none;
  z-index: 1;
  left: -8px;
  top: 36px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 8px 8px 0;
  border-color: transparent ${styles.color_accent} transparent transparent;

  ${props => props.active && css `
    display: block;
  `}
`

// BUTTONS

export const CalculatorFunctionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid ${styles.color_accent};
  padding: 0 0 ${styles.spacing_standard} 0;

  & * {
    font-family: ${styles.font_helv};
  }
`

export const CalculatorInputsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid ${styles.color_accent};

  & * {
    font-family: ${styles.font_helv};
  }
`

export const CalculatorInput = styled.div`
  cursor: pointer;
  transition: all 250ms;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  width: 25%;
  height: 50px;
  border-left: 1px solid ${styles.color_gry_2};
  border-bottom: 1px solid ${styles.color_gry_2};

  @media only screen and (max-width: ${styles.width_xs}) {
    height: 40px;
  }

  ${props => props.buttonOperation && css `
    background-color: ${styles.color_gry_1};

    :hover {
      background-color: ${styles.color_gry_2};
    }
  `}

  ${props => props.buttonNumber && css `
    :hover {
      background-color: ${styles.color_gry_1};
    }
  `}

  ${props => props.border && css `
    border-right: 1px solid ${styles.color_gry_2};
  `}

  ${props => props.half && css `
    width: 50%;
  `}

  ${props => props.clearButton && css `
    border: none;
    color: ${styles.color_white};
    background-color: ${styles.color_gry_2};
    cursor: not-allowed;
  `}

  ${props => props.clearState && css `
    color: ${styles.color_white};
    background-color: ${styles.color_red};
    cursor: pointer;

    :hover {
      background-color: ${styles.color_red_dark};
    }
  `}
`

export const CalculatorFunction = styled.div`
  cursor: pointer;
  transition: all 250ms;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: auto;
  min-width: 33.33%;
  height: 50px;
  border-left: 1px solid ${styles.color_gry_2};
  border-bottom: 1px solid ${styles.color_gry_2};
  background-color: ${styles.color_gry_1};

  :hover {
    background-color: ${styles.color_gry_2};
  }

  :nth-child(3n) {
    border-right: 1px solid ${styles.color_gry_2};
  }

  @media only screen and (max-width: ${styles.width_xs}) {
    height: 40px;
    font-size: 14px;
  }

  ${props => props.active && css `
    color: ${styles.color_white};
    background-color: ${styles.color_primary};
    border-left: 1px solid ${styles.color_primary};
    border-bottom: 1px solid ${styles.color_primary};
    cursor: default;

    :hover {
      color: ${styles.color_white};
      background-color: ${styles.color_primary};
      border-left: 1px solid ${styles.color_primary};
      border-bottom: 1px solid ${styles.color_primary};
      cursor: default;
    }
  `}
`

class Displays extends React.Component {

  render() {
    const { calculator, pound, kilogram, ounce, gram, milligram, microgram, mililiter, fluidOunces, fahrenheit, celsius } = this.props
    const language = navigator.language || 'en-US'

    // Sets Calculator (Default View) options and value
    // The Calculator view is shown in the active display
    // An active display is outlined in a darker gry and has a triangle in the upper right hand corner
    var calcOptions = {
      useGrouping: true,
      maximumFractionDigits: 3,
      notation: "standard"
    }
    // If a conversion display is active the scientific setting will be added at a smaller number to account for the half width displays
    if (this.props.display.calculatorDisplay) {
      if (Number(calculator) > 999999999999.999) {
        calcOptions.notation = "scientific"
      }
    } else {
      if (Number(calculator) > 99999.999) {
        calcOptions.notation = "scientific"
      }
    }
    let calculatorValue;
    if (String(calculator).includes('.0')) {
      let calculatorChunks = String(calculator).split('.')
      calculatorValue = Number(calculatorChunks[0]).toLocaleString(language, calcOptions) + '.' + String(calculatorChunks[1]).toLocaleString(language, calcOptions)
    } else {
      calculatorValue = Number(calculator).toLocaleString(language, calcOptions)
    }
    if (calculator.length > 0) {
      if ((calculator.charAt(calculator.length - 1) === ".")) {
        let calculatorChunks = String(calculator).split('.')
        calculatorValue = Number(calculatorChunks[0]).toLocaleString(language, calcOptions) + '.' + String(calculatorChunks[1]).toLocaleString(language, calcOptions)
      } else {
        if (String(calculator).includes('.0')) {
          let calculatorChunks = String(calculator).split('.')
          calculatorValue = Number(calculatorChunks[0]).toLocaleString(language, calcOptions) + '.' + String(calculatorChunks[1]).toLocaleString(language, calcOptions)
        } else {
          calculatorValue = Number(calculator).toLocaleString(language, calcOptions)
        }
      }
    }

    // -----------------------------------

    // Sets Pound options and value. This value is shown in the Pound display when the Fluid Ounces display is active (clicked)
    var poundOptions = {
      useGrouping: true,
      maximumFractionDigits: 3,
      notation: "standard"
    }
    // Scientific value is applied at max number
    if (Number(pound) > 99999.999) {
      poundOptions.notation = "scientific"
    }
    let poundValue = Number(pound).toLocaleString(language, poundOptions)

    // -----------------------------------

    // Sets Kilogram options and value. This value is shown in the Kilogram display when the Fluid Ounces display is active (clicked)
    var kilogramOptions = {
      useGrouping: true,
      maximumFractionDigits: 3,
      notation: "standard"
    }
    // Scientific value is applied at max number
    if (Number(kilogram) > 99999.999) {
      kilogramOptions.notation = "scientific"
    }
    let kilogramValue = Number(kilogram).toLocaleString(language, kilogramOptions)

    // -----------------------------------

    // Sets Ounce options and value. This value is shown in the Ounce display when the Fluid Ounces display is active (clicked)
    var ounceOptions = {
      useGrouping: true,
      maximumFractionDigits: 3,
      notation: "standard"
    }
    // Scientific value is applied at max number
    if (Number(ounce) > 99999.999) {
      ounceOptions.notation = "scientific"
    }
    let ounceValue = Number(ounce).toLocaleString(language, ounceOptions)

    // -----------------------------------

    // Sets Gram options and value. This value is shown in the Gram display when the Fluid Ounces display is active (clicked)
    var gramOptions = {
      useGrouping: true,
      maximumFractionDigits: 3,
      notation: "standard"
    }
    // Scientific value is applied at max number
    if (Number(gram) > 99999.999) {
      gramOptions.notation = "scientific"
    }
    let gramValue = Number(gram).toLocaleString(language, gramOptions)

    // -----------------------------------

    // Sets Milligram options and value. This value is shown in the Milligram display when the Fluid Ounces display is active (clicked)
    var milligramOptions = {
      useGrouping: true,
      maximumFractionDigits: 3,
      notation: "standard"
    }
    // Scientific value is applied at max number
    if (Number(milligram) > 99999.999) {
      milligramOptions.notation = "scientific"
    }
    let milligramValue = Number(milligram).toLocaleString(language, milligramOptions)

    // -----------------------------------

    // Sets Microgram options and value. This value is shown in the Microgram display when the Fluid Ounces display is active (clicked)
    var microgramOptions = {
      useGrouping: true,
      maximumFractionDigits: 3,
      notation: "standard"
    }
    // Scientific value is applied at max number
    if (Number(microgram) > 99999.999) {
      microgramOptions.notation = "scientific"
    }
    let microgramValue = Number(microgram).toLocaleString(language, microgramOptions)

    // -----------------------------------

    // Sets Mililiter options and value. This value is shown in the Mililiter display when the Fluid Ounces display is active (clicked)
    var mililiterOptions = {
      useGrouping: true,
      maximumFractionDigits: 3,
      notation: "standard"
    }
    // Scientific value is applied at max number
    if (Number(mililiter) > 99999.999) {
      mililiterOptions.notation = "scientific"
    }
    let mililiterValue = Number(mililiter).toLocaleString(language, mililiterOptions)

    // -----------------------------------

    // Sets Fluid Ounces options and value. This value is shown in the Fluid Ounces display when the Mililiter display is active (clicked)
    var fluidOuncesOptions = {
      useGrouping: true,
      maximumFractionDigits: 3,
      notation: "standard"
    }
    // Scientific value is applied at max number
    if (Number(fluidOunces) > 99999.999) {
      fluidOuncesOptions.notation = "scientific"
    }
    let fluidOuncesValue = Number(fluidOunces).toLocaleString(language, fluidOuncesOptions)

    // -----------------------------------

    // Sets Fahrenheit options and value. This value is shown in the Fahrenheit display when the Celsius display is active (clicked)
    var fahrenheitOptions = {
      useGrouping: true,
      maximumFractionDigits: 3,
      notation: "standard"
    }
    // Scientific value is applied at max number
    if (Number(fahrenheit) > 99999.999) {
      fahrenheitOptions.notation = "scientific"
    }
    let fahrenheitValue = Number(fahrenheit).toLocaleString(language, fahrenheitOptions)

    // -----------------------------------

    // Sets Celsius options and value. This value is shown in the Celsius display when the Fahrenheit display is active (clicked)
    var celsiusOptions = {
      useGrouping: true,
      maximumFractionDigits: 3,
      notation: "standard"
    }
    // Scientific value is applied at max number
    if (Number(celsius) > 99999.999) {
      celsiusOptions.notation = "scientific"
    }
    let celsiusValue = Number(celsius).toLocaleString(language, celsiusOptions)

    // -----------------------------------

    return (
      <CalculatorDisplaysWrapper>

        {/* Calculator (Default) display */}
        { this.props.display.calculatorDisplay ?
          <CalculatorDisplay active="true">
            {calculatorValue}
            <CalculatorTriangle active="true"></CalculatorTriangle>
            <CalculatorDisplayInfo>
            {this.props.operationValue}</CalculatorDisplayInfo>
          </CalculatorDisplay> : null}

        {/* Large Mass (Pounds/Kilograms) display */}
        { this.props.display.largeMassDisplay ?
          <CalculatorDisplays>
            {/* Pound display */}
            <CalculatorDisplay calculatorConverion="true" active={this.props.activeDisplay.poundDisplayActive} onClick={() => {this.props.toggleActiveDisplay('poundDisplayActive','poundValue')}}>
              { (this.props.activeDisplay.kilogramDisplayActive === true) ? poundValue : calculatorValue}
              <CalculatorTriangle active={this.props.activeDisplay.poundDisplayActive}></CalculatorTriangle>
              <CalculatorTriangleWhiteRight active={this.props.activeDisplay.poundDisplayActive}></CalculatorTriangleWhiteRight>
              <CalculatorTriangleGryRight active={this.props.activeDisplay.poundDisplayActive}></CalculatorTriangleGryRight>
              <CalculatorDisplayInfo>lb</CalculatorDisplayInfo>
            </CalculatorDisplay>
            {/* Kilogram display */}
            <CalculatorDisplay calculatorConverion="true" active={this.props.activeDisplay.kilogramDisplayActive} onClick={() => {this.props.toggleActiveDisplay('kilogramDisplayActive','kilogramValue')}}>
              { (this.props.activeDisplay.poundDisplayActive === true) ? kilogramValue : calculatorValue}
              <CalculatorTriangle active={this.props.activeDisplay.kilogramDisplayActive}></CalculatorTriangle>
              <CalculatorTriangleWhiteLeft active={this.props.activeDisplay.kilogramDisplayActive}></CalculatorTriangleWhiteLeft>
              <CalculatorTriangleGryLeft active={this.props.activeDisplay.kilogramDisplayActive}></CalculatorTriangleGryLeft>
              <CalculatorDisplayInfo>kg</CalculatorDisplayInfo>
            </CalculatorDisplay>
          </CalculatorDisplays> : null}

        {/* Medium Mass (Ounces/Grams) display */}
        { this.props.display.mediumMassDisplay ?
          <CalculatorDisplays>
            {/* Ounce display */}
            <CalculatorDisplay calculatorConverion="true" active={this.props.activeDisplay.ounceDisplayActive} onClick={() => {this.props.toggleActiveDisplay('ounceDisplayActive','ounceValue')}}>
              { (this.props.activeDisplay.gramDisplayActive === true) ? ounceValue : calculatorValue}
              <CalculatorTriangle active={this.props.activeDisplay.ounceDisplayActive}></CalculatorTriangle>
              <CalculatorTriangleWhiteRight active={this.props.activeDisplay.ounceDisplayActive}></CalculatorTriangleWhiteRight>
              <CalculatorTriangleGryRight active={this.props.activeDisplay.ounceDisplayActive}></CalculatorTriangleGryRight>
              <CalculatorDisplayInfo>oz</CalculatorDisplayInfo>
            </CalculatorDisplay>
            {/* Gram display */}
            <CalculatorDisplay calculatorConverion="true" active={this.props.activeDisplay.gramDisplayActive} onClick={() => {this.props.toggleActiveDisplay('gramDisplayActive','gramValue')}}>
              { (this.props.activeDisplay.ounceDisplayActive === true) ? gramValue : calculatorValue}
              <CalculatorTriangle active={this.props.activeDisplay.gramDisplayActive}></CalculatorTriangle>
              <CalculatorTriangleWhiteLeft active={this.props.activeDisplay.gramDisplayActive}></CalculatorTriangleWhiteLeft>
              <CalculatorTriangleGryLeft active={this.props.activeDisplay.gramDisplayActive}></CalculatorTriangleGryLeft>
              <CalculatorDisplayInfo>g</CalculatorDisplayInfo>
            </CalculatorDisplay>
          </CalculatorDisplays> : null}

        {/* Small Mass (Milligrams/Micrograms) display */}
        { this.props.display.smallMassDisplay ?
          <CalculatorDisplays>
            {/* Milligram display */}
            <CalculatorDisplay calculatorConverion="true" active={this.props.activeDisplay.milligramDisplayActive} onClick={() => {this.props.toggleActiveDisplay('milligramDisplayActive','milligramValue')}}>
              { (this.props.activeDisplay.microgramDisplayActive === true) ? milligramValue : calculatorValue}
              <CalculatorTriangle active={this.props.activeDisplay.milligramDisplayActive}></CalculatorTriangle>
              <CalculatorTriangleWhiteRight active={this.props.activeDisplay.milligramDisplayActive}></CalculatorTriangleWhiteRight>
              <CalculatorTriangleGryRight active={this.props.activeDisplay.milligramDisplayActive}></CalculatorTriangleGryRight>
              <CalculatorDisplayInfo>mg</CalculatorDisplayInfo>
            </CalculatorDisplay>
            {/* Microgram display */}
            <CalculatorDisplay calculatorConverion="true" active={this.props.activeDisplay.microgramDisplayActive} onClick={() => {this.props.toggleActiveDisplay('microgramDisplayActive','microgramValue')}}>
              { (this.props.activeDisplay.milligramDisplayActive === true) ? microgramValue : calculatorValue}
              <CalculatorTriangle active={this.props.activeDisplay.microgramDisplayActive}></CalculatorTriangle>
              <CalculatorTriangleWhiteLeft active={this.props.activeDisplay.microgramDisplayActive}></CalculatorTriangleWhiteLeft>
              <CalculatorTriangleGryLeft active={this.props.activeDisplay.microgramDisplayActive}></CalculatorTriangleGryLeft>
              <CalculatorDisplayInfo>mcg</CalculatorDisplayInfo>
            </CalculatorDisplay>
          </CalculatorDisplays> : null}

        {/* Volume (Mililiter/Fluid Ounces) display */}
        { this.props.display.volumeDisplay ?
          <CalculatorDisplays>
            {/* Mililiter display */}
            <CalculatorDisplay calculatorConverion="true" active={this.props.activeDisplay.mililiterDisplayActive} onClick={() => {this.props.toggleActiveDisplay('mililiterDisplayActive','mililiterValue')}}>
              { (this.props.activeDisplay.fluidOuncesDisplayActive === true) ? mililiterValue : calculatorValue}
              <CalculatorTriangle active={this.props.activeDisplay.mililiterDisplayActive}></CalculatorTriangle>
              <CalculatorTriangleWhiteRight active={this.props.activeDisplay.mililiterDisplayActive}></CalculatorTriangleWhiteRight>
              <CalculatorTriangleGryRight active={this.props.activeDisplay.mililiterDisplayActive}></CalculatorTriangleGryRight>
              <CalculatorDisplayInfo>ml</CalculatorDisplayInfo>
            </CalculatorDisplay>
            {/* Fluid Ounces display */}
            <CalculatorDisplay calculatorConverion="true" active={this.props.activeDisplay.fluidOuncesDisplayActive} onClick={() => {this.props.toggleActiveDisplay('fluidOuncesDisplayActive','fluidOuncesValue')}}>
              { (this.props.activeDisplay.mililiterDisplayActive === true) ? fluidOuncesValue : calculatorValue}
              <CalculatorTriangle active={this.props.activeDisplay.fluidOuncesDisplayActive}></CalculatorTriangle>
              <CalculatorTriangleWhiteLeft active={this.props.activeDisplay.fluidOuncesDisplayActive}></CalculatorTriangleWhiteLeft>
              <CalculatorTriangleGryLeft active={this.props.activeDisplay.fluidOuncesDisplayActive}></CalculatorTriangleGryLeft>
              <CalculatorDisplayInfo>fl oz</CalculatorDisplayInfo>
            </CalculatorDisplay>
          </CalculatorDisplays> : null}

        {/* Temperature (Fahrenheit/Celsius) display */}
        { this.props.display.temperatureDisplay ?
          <CalculatorDisplays>
            {/* Fahrenheit display */}
            <CalculatorDisplay calculatorConverion="true" active={this.props.activeDisplay.fahrenheitDisplayActive} onClick={() => {this.props.toggleActiveDisplay('fahrenheitDisplayActive','fahrenheitValue')}}>
              { (this.props.activeDisplay.celsiusDisplayActive === true) ? fahrenheitValue : calculatorValue}
              <CalculatorTriangle active={this.props.activeDisplay.fahrenheitDisplayActive}></CalculatorTriangle>
              <CalculatorTriangleWhiteRight active={this.props.activeDisplay.fahrenheitDisplayActive}></CalculatorTriangleWhiteRight>
              <CalculatorTriangleGryRight active={this.props.activeDisplay.fahrenheitDisplayActive}></CalculatorTriangleGryRight>
              <CalculatorDisplayInfo uppercase="true">F&#176;</CalculatorDisplayInfo>
            </CalculatorDisplay>
            {/* Celsius display */}
            <CalculatorDisplay calculatorConverion="true" active={this.props.activeDisplay.celsiusDisplayActive} onClick={() => {this.props.toggleActiveDisplay('celsiusDisplayActive','celsiusValue')}}>
              { (this.props.activeDisplay.fahrenheitDisplayActive === true) ? celsiusValue : calculatorValue}
              <CalculatorTriangle active={this.props.activeDisplay.celsiusDisplayActive}></CalculatorTriangle>
              <CalculatorTriangleWhiteLeft active={this.props.activeDisplay.celsiusDisplayActive}></CalculatorTriangleWhiteLeft>
              <CalculatorTriangleGryLeft active={this.props.activeDisplay.celsiusDisplayActive}></CalculatorTriangleGryLeft>
              <CalculatorDisplayInfo uppercase="true">C&#176;</CalculatorDisplayInfo>
            </CalculatorDisplay>
          </CalculatorDisplays> : null}

      </CalculatorDisplaysWrapper>
    )
  }
}

// Numbers and Calculator Functions Component
class CalculatorButtons extends React.Component {
  render() {
    const { onPress, buttonName, buttonOperation, buttonNumber, clearState, border, half, clearButton, ...props } = this.props

    return (
      <CalculatorInput onClick={onPress} buttonOperation={buttonOperation} buttonNumber={buttonNumber} clearState={clearState} buttonName={buttonName} border={border} half={half} clearButton={clearButton} {...props}></CalculatorInput>
    )
  }
}

// Calculator and Conversion Buttons Component
class DisplayButtons extends React.Component {
  render() {
    const { onPress, active, ...props } = this.props

    return (
      <CalculatorFunction onClick={onPress} active={active} {...props}></CalculatorFunction>
    )
  }
}

// Math
const CalculatorOperations = {
  '/': (prevValue, nextValue) => prevValue / nextValue,
  '*': (prevValue, nextValue) => prevValue * nextValue,
  '+': (prevValue, nextValue) => prevValue + nextValue,
  '-': (prevValue, nextValue) => prevValue - nextValue,
  '=': (prevValue, nextValue) => nextValue
}

export class Calculator extends React.Component {

  constructor() {
    super();
    this.state = {
      value: null,
      operationValue: '',
      operator: null,
      waitingForOperand: false,
      clearButton: false,
      insertDecimal: true,
      // Calculator and Conversion Values
      calculatorValue: '0',
      // Large mass
      poundValue: '0',
      kilogramValue: '0',
      // Medium mass
      ounceValue: '0',
      gramValue: '0',
      // Small mass
      milligramValue: '0',
      microgramValue: '0',
      // Volume
      mililiterValue: '0',
      fluidOuncesValue: '0',
      // Temperature
      fahrenheitValue: '0',
      celsiusValue: '0',
      // Sets display to show
      display: {
        calculatorDisplay: true,
        largeMassDisplay: false,
        mediumMassDisplay: false,
        smallMassDisplay: false,
        volumeDisplay: false,
        temperatureDisplay: false
      },
      // Sets "calculator-active" display
      activeDisplay: {
        calculatorDisplayActive: true,
        poundDisplayActive: false,
        kilogramDisplayActive: false,
        ounceDisplayActive: false,
        gramDisplayActive: false,
        milligramDisplayActive: false,
        microgramDisplayActive: false,
        mililiterDisplayActive: false,
        fluidOuncesDisplayActive: false,
        fahrenheitDisplayActive: false,
        celsiusDisplayActive: false,
      },
      // Sets a function button as active
      activeButton: {
        calculatorDisplay: true,
        largeMassDisplay: false,
        mediumMassDisplay: false,
        smallMassDisplay: false,
        volumeDisplay: false,
        temperatureDisplay: false,
      }
    };
  }

  // Clicking a Display Button (Calculator or Conversion) will show that display
  displayToggle(measurement, conversion) {

    const shownDisplays = this.state.display

    // If a display is already active, do not run
    if (!(this.state.display[conversion] === true)) {

      // Hides all displays then shows the requested display
      Object.keys(shownDisplays).forEach((value) => {
        shownDisplays[value] = false;
        shownDisplays[conversion] = true
      });
      this.setState({
        display: shownDisplays
      },
      () => {

        // Run
        this.activeDisplayToggle(measurement)
        this.activeButtonToggle(conversion)
        this.updateValues()

        // If the calculator value is not 0, turn the clear button on
        if (!(this.state.calculatorValue === '0')) {
          this.setState({
            clearButton: true
          })
        } else {
          this.setState({
            clearButton: false
          })
        }

        // If you're switching to the calculator, set the converted value to the calculatorValue
        if (this.state.display.calculatorDisplay === true) {
          this.convertedValueInCalculator()
        }
      })
    }
  }

  // Clicking on a display within a conversion (Mililiter / Fluid Ounces) will set that display to active
  activeDisplayToggle(measurement, conversion = false) {
    const activeDisplays = this.state.activeDisplay

    // If a display is already active when clicked, do not run
    if (!(activeDisplays[measurement] === true)) {

    // When a display is clicked, update the calculator value to the new display value

    // if (conversion) {
    //   let currentConversionValue = this.state[conversion]
    //
    //   this.setState({
    //     calculatorValue: String(currentConversionValue)
    //   },
    //   () => {
    //     this.updateValues()
    //
    //     // If the calculator value is not 0, turn the clear button on
    //     if (!(this.state.calculatorValue === '0')) {
    //       this.setState({
    //         clearButton: true
    //       })
    //     }
    //   })
    // }

    // Makes all displays inactive, then turns the display that was clicked to active
    Object.keys(activeDisplays).forEach((value) => {
      activeDisplays[value] = false;
    });
    activeDisplays[measurement] = true

    this.setState({
      waitingForOperand: true,
      activeDisplay: activeDisplays
    })

    }
  }

  convertedValueInCalculator() {
    this.setState({
      insertDecimal: false
    })
    // If pound take kilogram
    if (this.state.activeDisplay.poundDisplayActive === true) {
      this.setState({
        calculatorValue: this.state.kilogramValue
      })
    }
    // If kilograms take pound
    if (this.state.activeDisplay.kilogramDisplayActive === true) {
      this.setState({
        calculatorValue: this.state.poundValue
      })
    }
    // If ounce take gram
    if (this.state.activeDisplay.ounceDisplayActive === true) {
      this.setState({
        calculatorValue: this.state.gramValue
      })
    }
    // If gram take ounce
    if (this.state.activeDisplay.gramDisplayActive === true) {
      this.setState({
        calculatorValue: this.state.ounceValue
      })
    }
    // If milligram take microgram
    if (this.state.activeDisplay.milligramDisplayActive === true) {
      this.setState({
        calculatorValue: this.state.microgramValue
      })
    }
    // If microgram take milligram
    if (this.state.activeDisplay.microgramDisplayActive === true) {
      this.setState({
        calculatorValue: this.state.milligramValue
      })
    }
    // If mililiter take fluid ounce
    if (this.state.activeDisplay.mililiterDisplayActive === true) {
      this.setState({
        calculatorValue: this.state.fluidOuncesValue
      })
    }
    // If fluid ounce take mililiter
    if (this.state.activeDisplay.fluidOuncesDisplayActive === true) {
      this.setState({
        calculatorValue: this.state.mililiterValue
      })
    }
    // If fahrenheit take celsius
    if (this.state.activeDisplay.fahrenheitDisplayActive === true) {
      this.setState({
        calculatorValue: this.state.celsiusValue
      })
    }
    // If celsius take fahrenheit
    if (this.state.activeDisplay.celsiusDisplayActive === true) {
      this.setState({
        calculatorValue: this.state.fahrenheitValue
      })
    }
  }

  // Makes a Display Button active when clicked
  activeButtonToggle(conversion) {
    const activeButton = this.state.activeButton

    // Makes all function buttons inactive, then turns the button that was clicked to active
    Object.keys(activeButton).forEach((value) => {
      activeButton[value] = false
    });
    activeButton[conversion] = true
  }

  // Clears all values and settings when "Clear" is clicked
  clearAll() {
    this.setState({
      value: null,
      calculatorValue: '0',
      poundValue: '0',
      kilogramValue: '0',
      ounceValue: '0',
      gramValue: '0',
      milligramValue: '0',
      microgramValue: '0',
      mililiterValue: '0',
      fluidOuncesValue: '0',
      operator: null,
      waitingForOperand: false,
      operationValue: '',
      clearButton: false
    })
    // If the Fahrenheit display is active, set Celsius's value
    if (this.state.activeDisplay.fahrenheitDisplayActive === true) {
      this.setState({
        celsiusValue: '-17.77777777777777777777'
      })
    }
    // If the Celsius display is active, set Fahrenheit's value
    if (this.state.activeDisplay.celsiusDisplayActive === true) {
      this.setState({
        fahrenheitValue: '32'
      })
    }
  }

  // Clear a character from this display if the backspace is pressed
  clearLastChar() {
    const { calculatorValue } = this.state

    this.setState({
      calculatorValue: calculatorValue.substring(0, calculatorValue.length - 1) || '0'
    })
  }

  // Turns the current display value into a percentage
  inputPercent() {

    const { calculatorValue } = this.state
    const currentValue = parseFloat(calculatorValue)

    if (currentValue === 0)
    return

    const newValue = parseFloat(calculatorValue) / 100

    this.setState({
      calculatorValue: String(newValue.toFixed(5))
    },
    () => {
      this.updateValues();
    })
  }

  // Inserts a decimal when the dot button is pressed
  inputDot() {

    const { calculatorValue } = this.state

    // If insertDecimal is true, append the decimal to the end of the current number, else start a new number starting with 0
    if (this.state.insertDecimal) {
      if (!(/\./).test(calculatorValue)) {
        this.setState({
          calculatorValue: calculatorValue + '.',
          waitingForOperand: false
        })
      }
    } else {
      this.setState({
        calculatorValue: '0.',
        waitingForOperand: false
      })
    }

    this.updateValues();
    this.clearButtonOn();
  }

  // Inputs a digit when a number button is pressed
  inputDigit(digit) {

    const { calculatorValue, waitingForOperand } = this.state

    // If waitingForOperand is true, start a new number string, else append the number to the end of the current number
    if (waitingForOperand) {
      this.setState({
        calculatorValue: String(digit),
        waitingForOperand: false
      },
      () => {
        this.updateValues();
      })
    } else {
      this.setState({
        calculatorValue: calculatorValue === '0' ? String(digit) : calculatorValue + digit
      },
      () => {
        this.updateValues();
      })
    }

    // Once a number is clicked a decimal can be appended to it
    this.setState({
      insertDecimal: true
    })

    if (!(this.state.calculatorValue === "0")) {
      this.clearButtonOn();
    }
  }

  // Performs a calculator operation when a function button is pressed
  performOperation(nextOperator) {

    const { value, calculatorValue, operator } = this.state
    const inputValue = parseFloat(calculatorValue)

    // Changed the displayed number to the correct symbol
    var displayedOperator = ""
    if (nextOperator === "*") {
      displayedOperator = 'x'
    } else if (nextOperator === "/") {
      displayedOperator = '\u00F7'
    } else {
      displayedOperator = nextOperator
    }

    // TO BE EXPLAINED
    if (value === null) {

      let displayedNumber = calculatorValue
      if (String(calculatorValue).indexOf('.') !== -1) {
        displayedNumber = parseFloat(Number(calculatorValue).toFixed(3))
      }

      this.setState({
        value: inputValue,
        operationValue: nextOperator === "=" ? "" : (displayedNumber + " " + displayedOperator),
      },
      () => {
        this.updateValues();
      })
    } else if (operator) {
      const currentValue = value || 0
      const newValue = CalculatorOperations[operator](currentValue, inputValue)

      let displayedNumber = newValue

      if (String(newValue).indexOf('.') !== -1) {
        displayedNumber = parseFloat(Number(newValue).toFixed(3))
      }

      this.setState({
        value: newValue,
        calculatorValue: String(newValue),
        operationValue: nextOperator === "=" ? "" : (displayedNumber + " " + displayedOperator),
      },
      () => {
        this.updateValues();
      })

      if (!(nextOperator === "=" && newValue === "0")) {
        this.clearButtonOn();
      } else {
        this.setState({
          clearButton: false
        })
      }
    }

    // After a calculator function is pressed the decimal button starts a new number instead of appending to the current number
    this.setState({
      waitingForOperand: true,
      operator: nextOperator,
      insertDecimal: false
    })

    this.updateValues();
  }

  // Updates the conversion values after math is applied
  updateValues() {
    const calculatorDisplayValue = this.state.calculatorValue

    this.setState({
      // Large mass
      poundValue: Number(calculatorDisplayValue) * 2.205,
      kilogramValue: Number(calculatorDisplayValue) / 2.205,
      // Medium mass
      ounceValue: Number(calculatorDisplayValue) / 28.35,
      gramValue: Number(calculatorDisplayValue) * 28.35,
      // Small mass
      milligramValue: Number(calculatorDisplayValue) / 1000,
      microgramValue: Number(calculatorDisplayValue) * 1000,
      // Volume
      mililiterValue: Number(calculatorDisplayValue) * 29.574,
      fluidOuncesValue: Number(calculatorDisplayValue) / 29.574,
      // Temperature
      fahrenheitValue: (Number(calculatorDisplayValue) * (9/5)) + 32,
      celsiusValue: (Number(calculatorDisplayValue) - 32) * (5/9),
    },
    () => {
      // If the calculator value is not 0, turn the clear button on
      if (!(this.state.calculatorValue === '0')) {
        this.setState({
          clearButton: true
        })
      }
    })
  }

  // Makes the clear button active after an action
  clearButtonOn() {
    this.setState({
      clearButton: true
    })
  }

  // Handles key presses
  handleKeyDown = (event) => {
    let { key } = event

    if (key === 'Enter')
      key = '='

    if ((/\d/).test(key)) {
      event.preventDefault()
      this.inputDigit(parseInt(key, 10))
    } else if (key in CalculatorOperations) {
      event.preventDefault()
      this.performOperation(key)
    } else if (key === '.') {
      event.preventDefault()
      this.inputDot()
    } else if (key === '%') {
      event.preventDefault()
      this.inputPercent()
    } else if (key === 'Backspace') {
      event.preventDefault()
      this.clearLastChar()
    } else if (key === 'Clear') {
      event.preventDefault()

      if (this.state.calculatorValue !== '0') {
        this.clearDisplay()
      } else {
        this.clearAll()
      }
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  closeCalculator() {
    alert('test')
  }

  render() {
    const { calculatorValue, poundValue, kilogramValue, ounceValue, gramValue, milligramValue, microgramValue, mililiterValue, fluidOuncesValue, fahrenheitValue, celsiusValue, clearButton } = this.state

    const clearState = clearButton ? true : false

    return (
      <CalculatorContainer>
        <CalculatorWrapper>
          {/* All Displays */}
          <Displays calculator={calculatorValue} pound={poundValue} kilogram={kilogramValue} ounce={ounceValue} gram={gramValue} milligram={milligramValue} microgram={microgramValue} mililiter={mililiterValue} fluidOunces={fluidOuncesValue} fahrenheit={fahrenheitValue} celsius={celsiusValue} display={this.state.display} activeDisplay={this.state.activeDisplay} toggleActiveDisplay={this.activeDisplayToggle.bind(this)} operationValue={this.state.operationValue}/>

          {/* All Display Buttons */}
          <CalculatorFunctionsWrapper>

            {/* Calculator Button */}
            <DisplayButtons onPress={() => this.displayToggle("calculatorDisplayActive", "calculatorDisplay")} active={this.state.activeButton.calculatorDisplay}>Calculator</DisplayButtons>

            {/* Large Mass Button */}
            <DisplayButtons onPress={() => this.displayToggle("poundDisplayActive", "largeMassDisplay")} active={this.state.activeButton.largeMassDisplay}>lbs / kg</DisplayButtons>

            {/* Medium Mass Button */}
            <DisplayButtons onPress={() => this.displayToggle("ounceDisplayActive", "mediumMassDisplay")} active={this.state.activeButton.mediumMassDisplay}>oz / g</DisplayButtons>

            {/* Small Mass Button */}
            <DisplayButtons onPress={() => this.displayToggle("milligramDisplayActive", "smallMassDisplay")} active={this.state.activeButton.smallMassDisplay}>mg / mcg</DisplayButtons>

            {/* Volume Button */}
            <DisplayButtons onPress={() => this.displayToggle("mililiterDisplayActive", "volumeDisplay")} active={this.state.activeButton.volumeDisplay}>ml / fl oz</DisplayButtons>

            {/* Temperature Button */}
            <DisplayButtons onPress={() => this.displayToggle("fahrenheitDisplayActive", "temperatureDisplay")} active={this.state.activeButton.temperatureDisplay}>F&#176; / C&#176;</DisplayButtons>

          </CalculatorFunctionsWrapper>

          {/* All Calculator Buttons */}
          <CalculatorInputsWrapper>

            {/* Row 1 */}
            <CalculatorButtons buttonName="calculator-key-clear" clearState={clearState} clearButton="true" half="true" onPress={() => this.clearAll()}>Clear</CalculatorButtons>
            <CalculatorButtons buttonName="calculator-key-percent" buttonOperation="true" onPress={() => this.inputPercent()}>%</CalculatorButtons>
            <CalculatorButtons border="true" buttonOperation="true" onPress={() => this.performOperation('/')}>÷</CalculatorButtons>

            {/* Row 2 */}
            <CalculatorButtons buttonName="calculator-key-7" buttonNumber="true" onPress={() => this.inputDigit(7)}>7</CalculatorButtons>
            <CalculatorButtons buttonName="calculator-key-8" buttonNumber="true" onPress={() => this.inputDigit(8)}>8</CalculatorButtons>
            <CalculatorButtons buttonName="calculator-key-9" buttonNumber="true" onPress={() => this.inputDigit(9)}>9</CalculatorButtons>

            {/* Row 3 */}
            <CalculatorButtons border="true" buttonOperation="true" onPress={() => this.performOperation('*')}>×</CalculatorButtons>
            <CalculatorButtons buttonName="calculator-key-4" buttonNumber="true" onPress={() => this.inputDigit(4)}>4</CalculatorButtons>
            <CalculatorButtons buttonName="calculator-key-5" buttonNumber="true" onPress={() => this.inputDigit(5)}>5</CalculatorButtons>
            <CalculatorButtons buttonName="calculator-key-6" buttonNumber="true" onPress={() => this.inputDigit(6)}>6</CalculatorButtons>

            {/* Row 4 */}
            <CalculatorButtons border="true" buttonOperation="true" onPress={() => this.performOperation('-')}>−</CalculatorButtons>
            <CalculatorButtons buttonName="calculator-key-1" buttonNumber="true" onPress={() => this.inputDigit(1)}>1</CalculatorButtons>
            <CalculatorButtons buttonName="calculator-key-2" buttonNumber="true" onPress={() => this.inputDigit(2)}>2</CalculatorButtons>
            <CalculatorButtons buttonName="calculator-key-3" buttonNumber="true" onPress={() => this.inputDigit(3)}>3</CalculatorButtons>

            {/* Row 5 */}
            <CalculatorButtons border="true" buttonOperation="true" onPress={() => this.performOperation('+')}>+</CalculatorButtons>
            <CalculatorButtons buttonName="calculator-key-0" buttonNumber="true" half="true" onPress={() => this.inputDigit(0)}>0</CalculatorButtons>
            <CalculatorButtons buttonName="calculator-key-dot" buttonNumber="true" onPress={() => this.inputDot()}>.</CalculatorButtons>
            <CalculatorButtons border="true" buttonOperation="true" onPress={() => this.performOperation('=')}>=</CalculatorButtons>

          </CalculatorInputsWrapper>
          <CalculatorCloseButton onClick={() => {this.props.toggleCalulator()}} className="fa fa-times-circle display-none-important"></CalculatorCloseButton>
        </CalculatorWrapper>
      </CalculatorContainer>
    )
  }
}
