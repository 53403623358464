import React from 'react';
import styled, { css } from 'styled-components';
import * as styles from '../Styles/Stylesheet.js';

const SearchCardContainer = styled.div`
  display: block;
  padding: ${styles.spacing_standard_half};
  width: 33.33%;

  @media (max-width: ${styles.width_mn}) {
    width: 50%;
  }

  @media (max-width: ${styles.width_nrw}) {
    width: 100%;
  }
`

const SearchCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: ${styles.color_white};
  position: relative;
  transition: all 250ms;
  border: 1px solid ${styles.color_white};
  cursor: pointer;

  :hover {
    box-shadow: ${styles.box_shadow};
    transition: all 250ms;
  }

  ${props => props.selectedCard && css`
    border: 1px solid ${styles.color_primary};
    box-shadow: ${styles.box_shadow};
  `}
`

const SearchSelectedTriangle = styled.div`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0px 0px 0;
  border-color: transparent ${styles.color_primary} transparent transparent;
  transition: all 250ms;

  ${props => props.selectedCard && css`
    transition: all 250ms;
    border-width: 0 20px 20px 0;
  `}
`

const SearchCardSpecies = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: start;
  padding: ${styles.container_standard};
  color: ${styles.color_gry_6};
  font-family: Source Sans Pro, Sans-serif;
  font-size: 20px;
  font-weight: ${styles.font_bold};
  line-height: 1;
  overflow-wrap: break-word;
  text-transform: capitalize;

  @media (max-width: ${styles.width_nrw}) {
    min-height: auto;
  }
`

const SearchCardScientific = styled.div`
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: ${styles.font_bold};
  font-size: 14px;
  color: ${styles.color_gry_3};
`

const SearchCardTag = styled.div`
  padding: 4px 8px;
  margin: ${styles.spacing_standard} 0 0 0;
  color: ${styles.color_white};
  background-color: ${styles.color_gry_4};
  font-family: Source Sans Pro, Sans-serif;
  font-size: 10px;
  font-weight: ${styles.font_bold};
  text-transform: uppercase;
  border-radius: 3px;

  ${props => props.omnivore && css`
    background-color: ${styles.color_blue};
  `}

  ${props => props.carnivore && css`
    background-color: ${styles.color_red};
  `}

  ${props => props.herbivore && css`
    background-color: ${styles.color_green};
  `}
`

const Line = styled.div`
  width: 100%;
  border-top: 1px solid ${styles.color_gry_2};
  margin: ${styles.spacing_standard} 0 ${styles.spacing_standard_half} 0;
`


export class SearchCard extends React.Component {

  render() {

    const searchID = this.props.id

    return (
      <SearchCardContainer>
        <SearchCardWrapper selectedCard={this.props.selectedCard}>
          <SearchSelectedTriangle selectedCard={this.props.selectedCard} />
          <SearchCardSpecies onClick={() => {this.props.toggleAnimal(searchID)}}>{this.props.species}
          <Line/>
          <SearchCardScientific>{this.props.scientific}</SearchCardScientific>
          {this.props.diet === "omnivore" ? <SearchCardTag omnivore>{this.props.diet}</SearchCardTag> : null}
          {this.props.diet === "herbivore" ? <SearchCardTag herbivore>{this.props.diet}</SearchCardTag> : null}
          {this.props.diet === "carnivore" ? <SearchCardTag carnivore>{this.props.diet}</SearchCardTag> : null}</SearchCardSpecies>
        </SearchCardWrapper>
      </SearchCardContainer>
    );
  }
}
