import "./checkbox.scss"
import React from "react"

const Checkbox = ({checked, onChange, label, disabled}) =>{

    return (
        <label className={`checkbox-wrapper${disabled ? ' disabled' : ''}`}>
            <input component="checkbox" type="checkbox" checked={checked} onChange={onChange} disabled={disabled} className="checkbox" />
            <span className="checkbox-box"></span>
            {label}
        </label>
    )
}
 
export default Checkbox

 