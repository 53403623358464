import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AppHeader from "./StyleGuide/Organisms/app-header/AppHeader";
import Footer from "./StyleGuide/Organisms/Footer";
import Resume from "./Pages/Resume";
import StyleGuide from "./Pages/style-guide/StyleGuide";
import ConversionCalculator from "./Pages/ConversionCalculator";
import Homepage from "./Pages/homepage/Homepage";
import CharacterSheets from "./Pages/CharacterSheets";
import Piano from "./Pages/Piano";
import Movementum from "./Pages/Movementum";
import HateList from "./Hate/HateList";
import Test from "./Pages/Test";
import NotFoundPage from "./Pages/NotFoundPage";

class App extends Component {
  render() {
    return (
      <Router>
        <AppHeader />
        <Switch>
          <Route exact path="/">
            <Homepage />
          </Route>
          <Route exact path="/resume">
            <Resume />
          </Route>
          <Route exact path="/style-guide">
            <StyleGuide />
          </Route>
          <Route exact path="/conversion-calculator">
            <ConversionCalculator />
          </Route>
          <Route exact path="/piano">
            <Piano />
          </Route>
          <Route exact path="/character-sheets">
            <CharacterSheets />
          </Route>
          <Route exact path="/hate">
            <HateList />
          </Route>
          <Route exact path="/test">
            <Test />
          </Route>
          <Route exact path="/movementum">
            <Movementum />
          </Route>
          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
        <Footer />
      </Router>
    );
  }
}

export default App;
