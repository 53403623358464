export const bounce_in_forward = "animation:bounce-in-fwd 1000ms both; @keyframes bounce-in-fwd{0%{transform:scale(0);animation-timing-function:ease-in;opacity:0}38%{transform:scale(1);animation-timing-function:ease-out;opacity:1}55%{transform:scale(.7);animation-timing-function:ease-in}72%{transform:scale(1);animation-timing-function:ease-out}81%{transform:scale(.84);animation-timing-function:ease-in}89%{transform:scale(1);animation-timing-function:ease-out}95%{transform:scale(.95);animation-timing-function:ease-in}100%{transform:scale(1);animation-timing-function:ease-out}}";

export const bounce_in_back = "animation:bounce-in-back 1000ms both; @keyframes bounce-in-back{0%{transform:scale(7);animation-timing-function:ease-in;opacity:0}38%{transform:scale(1);animation-timing-function:ease-out;opacity:1}55%{transform:scale(1.5);animation-timing-function:ease-in}72%{transform:scale(1);animation-timing-function:ease-out}81%{transform:scale(1.24);animation-timing-function:ease-in}89%{transform:scale(1);animation-timing-function:ease-out}95%{transform:scale(1.04);animation-timing-function:ease-in}100%{transform:scale(1);animation-timing-function:ease-out}}";

export const fade_in = "animation:fade-in 750ms cubic-bezier(.39,.57,.56,1) both; @keyframes fade-in{0%{opacity:0}100%{opacity:1}}";

export const fade_out = "animation:fade-out 750ms ease-out both; @keyframes fade-out{0%{opacity:1}100%{opacity:0}}";

export const fade_in_forward = "animation:fade-in-fwd 500ms cubic-bezier(.39,.57,.56,1) both; @keyframes fade-in-fwd{0%{transform:scale(.8);opacity:0}100%{transform:scale(1);opacity:1}}";

export const fade_out_forward = "animation:fade-out-fwd 500ms cubic-bezier(.25,.46,.45,.95) both; @keyframes fade-out-fwd{0%{transform:scale(1);opacity:1}100%{transform:scale(1.2);opacity:0}}";

export const fade_in_back = "animation:fade-in-bck 500ms cubic-bezier(.39,.57,.56,1) both; @keyframes fade-in-bck{0%{transform:scale(1.2);opacity:0}100%{transform:scale(1);opacity:1}}";

export const fade_out_back = "animation:fade-out-bck 500ms cubic-bezier(.25,.46,.45,.95) both; @keyframes fade-out-bck{0%{transform:scale(1);opacity:1}100%{transform:scale(.8);opacity:0}}";

export const fade_in_top = "animation:fade-in-top 750ms cubic-bezier(.39,.57,.56,1) both; @keyframes fade-in-top{0%{transform:translateY(-50px);opacity:0}100%{transform:translateY(0);opacity:1}}";

export const fade_out_top = "animation:fade-out-top 750ms cubic-bezier(.25,.46,.45,.95) both; @keyframes fade-out-top{0%{transform:translateY(0);opacity:1}100%{transform:translateY(-50px);opacity:0}}";

export const fade_in_bottom = "animation:fade-in-bottom 750ms cubic-bezier(.39,.57,.56,1) both; @keyframes fade-in-bottom{0%{transform:translateY(50px);opacity:0}100%{transform:translateY(0);opacity:1}}";

export const fade_out_bottom = "animation:fade-out-bottom 750ms cubic-bezier(.25,.46,.45,.95) both; @keyframes fade-out-bottom{0%{transform:translateY(0);opacity:1}100%{transform:translateY(50px);opacity:0}}";

export const fade_in_right = "animation:fade-in-right 500ms cubic-bezier(.39,.57,.56,1) both; @keyframes fade-in-right{0%{transform:translateX(50px);opacity:0}100%{transform:translateX(0);opacity:1}}";

export const fade_out_right = "animation:fade-out-right 500ms cubic-bezier(.25,.46,.45,.95) both; @keyframes fade-out-right{0%{transform:translateX(0);opacity:1}100%{transform:translateX(50px);opacity:0}}";

export const fade_in_left = "animation:fade-in-left 500ms cubic-bezier(.39,.57,.56,1) both; @keyframes fade-in-left{0%{transform:translateX(-50px);opacity:0}100%{transform:translateX(0);opacity:1}}";

export const fade_out_left = "animation:fade-out-left 500ms cubic-bezier(.25,.46,.45,.95) both; @keyframes fade-out-left{0%{transform:translateX(0);opacity:1}100%{transform:translateX(-50px);opacity:0}}";

export const slide_in_right = "animation:slide-in-right 500ms cubic-bezier(.25,.46,.45,.94) both; @keyframes slide-in-right{0%{transform:translateX(1000px);opacity:0}100%{transform:translateX(0);opacity:1}}";

export const slide_out_right = "animation:slide-out-right 500ms cubic-bezier(.5,.08,.68,.53) both; @keyframes slide-out-right{0%{transform:translateX(0);opacity:1}100%{transform:translateX(1000px);opacity:0}}";

export const slide_in_left = "animation:slide-in-left 500ms cubic-bezier(.25,.46,.45,.94) both; @keyframes slide-in-left{0%{transform:translateX(-1000px);opacity:0}100%{transform:translateX(0);opacity:1}}";

export const slide_out_left = "animation:slide-out-left 500ms cubic-bezier(.5,.08,.68,.53) both; @keyframes slide-out-left{0%{transform:translateX(0);opacity:1}100%{transform:translateX(-1000px);opacity:0}}";

export const shake = "animation:shake 1000ms cubic-bezier(.45,.03,.51,.95) both; @keyframes shake{0%,100%{transform:rotate(0);transform-origin:50% 50%}10%{transform:rotate(8deg)}20%,40%,60%{transform:rotate(-10deg)}30%,50%,70%{transform:rotate(10deg)}80%{transform:rotate(-8deg)}90%{transform:rotate(8deg)}}";

export const pulsate_back = "animation:pulsate-bck 1000ms ease-in-out infinite both; @keyframes pulsate-bck{0%{-webkit-transform:scale(1);transform:scale(1)}50%{-webkit-transform:scale(.9);transform:scale(.9)}100%{-webkit-transform:scale(1);transform:scale(1)}}";

export const pulsate_forward = "animation:pulsate-fwd 1000ms ease-in-out infinite both; @keyframes pulsate-fwd{0%{-webkit-transform:scale(1);transform:scale(1)}50%{-webkit-transform:scale(1.1);transform:scale(1.1)}100%{-webkit-transform:scale(1);transform:scale(1)}}";

export const heartbeat = "animation:heartbeat 1000ms ease-in-out infinite both; @keyframes heartbeat{from{transform:scale(1);transform-origin:center center;animation-timing-function:ease-out}10%{transform:scale(.91);animation-timing-function:ease-in}17%{transform:scale(.98);animation-timing-function:ease-out}33%{transform:scale(.87);animation-timing-function:ease-in}45%{transform:scale(1);animation-timing-function:ease-out}}";
