import React from 'react';
import styled, { css } from 'styled-components';
import * as styles from '../Styles/Stylesheet.js';

const InputContainer = styled.input`
  position: relative;
  width: 100%;
  height: 52px;
  padding: ${styles.spacing_standard_half};
  margin: 0 0 ${styles.spacing_standard} 0;
  font-family: ${styles.font_sans};
  font-size: 16px;
  color: ${styles.color_gry_6};
  line-height: 1;
  background-color: ${styles.color_white};
  border: 1px solid ${styles.color_gry_2};
  border-radius: 0px;
  box-shadow: none;
  cursor: text;
  outline: 0;
  transition: all 100ms;
  -webkit-appearance: none;

  ::placeholder {
    color: ${styles.color_gry_4};
  }

  :hover {
    border: 1px solid ${styles.color_gry_3};
  }

  ${props => props.error && css `
    background-color: ${styles.color_gry_1};
    border: 1px solid ${styles.color_red};

    :hover {
      border: 1px solid ${styles.color_red};
    }
  `}

  ${props => props.active && css `
    background-color: ${styles.color_white};
    border: 1px solid ${styles.color_primary};

    :hover {
      border: 1px solid ${styles.color_primary};
    }
  `}

  ${props => props.disabled && css `
    color: ${styles.color_gry_4};
    cursor: not-allowed;

    :hover {
      border: 1px solid ${styles.color_gry_2} !important;
    }

    ::placeholder {
      color: ${styles.color_gry_2};
    }
  `}
`

export class Input extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      active: false,
      disable: this.props.disable,
      value: this.props.value
    };
    this.handleInput = this.handleInput.bind(this);
  }

  componentDidMount() {
    document.addEventListener("click", this.handleState.bind(this), false);
  }

  handleState(e) {
    if (this.node && this.node.contains(e.target)) {
      this.activeInput();
      return;
    }

    this.inactiveInput();
  }

  activeInput() {
    if (this.state.disable) return

    this.setState({
      active: true
    })
  }

  inactiveInput() {
    if (this.state.disable) return

    this.setState({
      active: false
    })
  }

  handleInput(event) {
    this.setState({
      value: event.target.value
    });

    this.props.inputValue(event.target.value)
  }

  render() {

    return (
      <InputContainer ref={node => this.node = node} onKeyUp={(e) => {this.activeInput(e)}} onBlur={() => {this.inactiveInput()}} type={this.props.type} error={this.props.error} disabled={this.props.disable} value={this.props.value} onChange={this.handleInput} active={this.state.active} placeholder={this.props.placeholder}/>
    );
  }
}
