import "./colors.scss"
import React from "react"
import variables from "../../Styles/variables.scss";

const Colors = () =>{

    return (
      <div className="colors-container">
        <div className="colors-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{backgroundColor: variables.primaryLight}}></div>
            <div className="color-information">
              <div className="color-hex">{variables.primaryLight}</div>
              <div className="color-name">primary-light</div>
            </div>
          </div>
        </div>
        <div className="colors-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.primary }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.primary}</div>
              <div className="color-name">primary</div>
            </div>
          </div>
        </div>
        <div className="colors-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.primaryDark }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.primaryDark}</div>
              <div className="color-name">primary-dark</div>
            </div>
          </div>
        </div>
        <div className="colors-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.secondaryLight }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.secondaryLight}</div>
              <div className="color-name">secondary-light</div>
            </div>
          </div>
        </div>
        <div className="colors-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.secondary }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.secondary}</div>
              <div className="color-name">secondary</div>
            </div>
          </div>
        </div>
        <div className="colors-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.secondaryDark }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.secondaryDark}</div>
              <div className="color-name">secondary-dark</div>
            </div>
          </div>
        </div>
        <div className="colors-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.accentLight }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.accentLight}</div>
              <div className="color-name">accent-light</div>
            </div>
          </div>
        </div>
        <div className="colors-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.accent }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.accent}</div>
              <div className="color-name">accent</div>
            </div>
          </div>
        </div>
        <div className="colors-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.accentDark }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.accentDark }</div>
              <div className="color-name">accent-dark</div>
            </div>
          </div>
        </div>
        <div className="line"></div>
        <div className="colors-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.red }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.red}</div>
              <div className="color-name">red</div>
            </div>
          </div>
        </div>
        <div className="colors-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.orange }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.orange}</div>
              <div className="color-name">orange</div>
            </div>
          </div>
        </div>
        <div className="colors-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.yellow }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.yellow}</div>
              <div className="color-name">yellow</div>
            </div>
          </div>
        </div>
        <div className="colors-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.green }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.green}</div>
              <div className="color-name">green</div>
            </div>
          </div>
        </div>
        <div className="colors-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.blue }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.blue}</div>
              <div className="color-name">blue</div>
            </div>
          </div>
        </div>
        <div className="colors-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.purple }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.purple}</div>
              <div className="color-name">purple</div>
            </div>
          </div>
        </div>
        <div className="colors-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.redDark }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.redDark}</div>
              <div className="color-name">red-dark</div>
            </div>
          </div>
        </div>
        <div className="colors-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.orangeDark }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.orangeDark}</div>
              <div className="color-name">orange-dark</div>
            </div>
          </div>
        </div>
        <div className="colors-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.yellowDark }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.yellowDark}</div>
              <div className="color-name">yellow-dark</div>
            </div>
          </div>
        </div>
        <div className="colors-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.greenDark }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.greenDark}</div>
              <div className="color-name">green-dark</div>
            </div>
          </div>
        </div>
        <div className="colors-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.blueDark }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.blueDark}</div>
              <div className="color-name">blue-dark</div>
            </div>
          </div>
        </div>
        <div className="colors-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.purpleDark }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.purpleDark}</div>
              <div className="color-name">purple-dark</div>
            </div>
          </div>
        </div>
        <div className="line"></div>
        <div className="grys-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.white }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.white}</div>
              <div className="color-name">white</div>
            </div>
          </div>
        </div>
        <div className="grys-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.gry1 }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.gry1}</div>
              <div className="color-name">gry1</div>
            </div>
          </div>
        </div>
        <div className="grys-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.gry2 }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.gry2}</div>
              <div className="color-name">gry2</div>
            </div>
          </div>
        </div>
        <div className="grys-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.gry3 }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.gry3}</div>
              <div className="color-name">gry3</div>
            </div>
          </div>
        </div>
        <div className="grys-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.gry4 }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.gry4}</div>
              <div className="color-name">gry4</div>
            </div>
          </div>
        </div>
        <div className="grys-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.gry5 }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.gry5}</div>
              <div className="color-name">gry5</div>
            </div>
          </div>
        </div>
        <div className="grys-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.gry6 }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.gry6}</div>
              <div className="color-name">gry6</div>
            </div>
          </div>
        </div>
        <div className="grys-wrapper">
          <div className="colors-shadow">
            <div className="color" style={{ backgroundColor: variables.black }}></div>
            <div className="color-information">
              <div className="color-hex">{variables.black}</div>
              <div className="color-name">black</div>
            </div>
          </div>
        </div>
      </div>
    )
}
 
export default Colors

 