import styled, { css } from 'styled-components';
import * as styles from '../Styles/Stylesheet.js';

export const Title = styled.div`
  width: 100%;
  padding: 0 0 ${styles.spacing_standard} 0;
  font-family: ${styles.font_sans};
  font-size: 32px;
  font-weight: ${styles.font_bold};
  color: ${styles.color_gry_6};
  line-height: 1.2;

  @media only screen and (max-width: ${styles.width_md}) {
    font-size: 28px;
  }

  ${props => props.center && css `
    text-align: center;
  `}

  ${props => props.color && css `
    color: ${props => props.color};
  `}
`

export const DisplayTitle = styled.div`
  padding: 0 0 ${styles.spacing_standard} 0;
  font-family: ${styles.font_display};
  font-size: 28px;
  font-weight: ${styles.font_bold};
  text-transform: uppercase;
  color: ${styles.color_gry_6};
  line-height: 1;

  @media only screen and (max-width: ${styles.width_md}) {
    font-size: 28px;
  }

  ${props => props.center && css `
    text-align: center;
  `}

  ${props => props.color && css `
    color: ${props => props.color};
  `}
`

export const Information = styled.div`
  width: 100%;
  font-family: ${styles.font_sans};
  font-size: 22px;
  font-weight: ${styles.font_thin};
  color: ${styles.color_gry_6};
  padding: 0 0 ${styles.spacing_standard};
  line-height: 1.2;

  ${props => props.center && css `
    text-align: center;
  `}

  ${props => props.color && css `
    color: ${props => props.color};
  `}
`

export const Header = styled.div`
  width: 100%;
  padding: 0 0 ${styles.container_standard_half} 0;
  font-family: ${styles.font_display};
  font-size: 26px;
  font-weight: ${styles.font_bold};
  color: ${styles.color_gry_6};
  line-height: 1;

  ${props => props.center && css `
    text-align: center;
  `}

  ${props => props.color && css `
    color: ${props => props.color};
  `}
`

export const Subheader = styled.div`
  width: 100%;
  padding: 0 0 ${styles.container_standard_half} 0;
  font-family: ${styles.font_sans};
  font-size: 18px;
  font-weight: ${styles.font_bold};
  text-transform: uppercase;
  color: ${styles.color_gry_6};
  line-height: 1;

  ${props => props.center && css `
    text-align: center;
  `}

  ${props => props.color && css `
    color: ${props => props.color};
  `}
`

export const Tag = styled.span`
  padding: 0 0 ${styles.spacing_standard_half} 0;
  font-family: ${styles.font_sans};
  font-size: 14px;
  font-weight: ${styles.font_bold};
  text-transform: uppercase;
  text-decoration: none;
  color: ${styles.color_accent};
  line-height: 1;
  transition: all 250ms;

  ${props => props.center && css `
    text-align: center;
  `}

  ${props => props.color && css `
    color: ${props => props.color};
  `}
`

export const Footnote = styled.span`
  flex: 1 0 auto;
  padding: 0 0 ${styles.spacing_standard_half} 0;
  color: ${styles.color_gry_4};
  font-family: ${styles.font_sans};
  line-height: 1.1;
  font-weight: ${styles.font_normal};
  font-size: 14px;

  ${props => props.center && css `
    text-align: center;
  `}

  ${props => props.color && css `
    color: ${props => props.color};
  `}
`

export const Body = styled.div`
  width: 100%;
  font-family: ${styles.font_sans};
  font-size: 16px;
  font-weight: ${styles.font_normal};
  color: ${styles.color_gry_6};
  line-height: 1.4;

  ${props => props.center && css `
    text-align: center;
  `}

  ${props => props.color && css `
    color: ${props => props.color};
  `}

  & p {
    margin: 0;
    padding: 0 0 ${styles.spacing_standard} 0;
  }

  & li {
    padding: 0 0 10px 0;
  }

  & li p {
    padding: 0;
  }

  & > ul {
    margin: 0;
    padding: 0 0 ${styles.spacing_standard} 18px;
  }

  & > ul li:last-child {
    margin: 0;
    padding: 0;
  }

  & > ul > li > ul {
    margin: 0;
    padding: 10px 0 0 18px;
    list-style: none;
  }

  & > ul > li > ul > li:before {
    content: "\\2013";
    margin-right: 12px;
    font-weight: ${styles.font_bold};
    margin-left: -20px;
  }

  & > ul > li > ul > li > ul {
    margin: 0;
    padding: 10px 0 0 18px;
    list-style: circle;
  }

  & > ol {
    margin: 0;
    padding: 0 0 ${styles.spacing_standard} ${styles.container_standard_half};
  }

  & > ol li:last-child {
    margin: 0;
    padding: 0;
  }

  & > ol > li {
    margin: 0;
    padding: 0 0 10px 6px;
  }

  & > ol > li:last-child {
    margin: 0;
    padding: 0 0 0 6px;
  }

  & > ol > li > ul {
    margin: 0;
    padding: 10px 0 0 18px;
    list-style: none;
  }

  & > ol > li > ul > li:before {
    content: "\\2013";
    margin-right: 12px;
    font-weight: ${styles.font_bold};
    margin-left: -20px;
  }

  & > ol > li > ul > li > ul {
    margin: 0;
    padding: 10px 0 0 18px;
    list-style: circle;
  }

  & p.rteindent1 {
    padding: 0 0 ${styles.spacing_standard} ${styles.spacing_standard};
  }

  & p.rteindent2 {
    padding: 0 0 ${styles.spacing_standard} ${styles.spacing_standard};
  }
`

export const Icon = styled.i`
  font-size: 14px;
  color: ${styles.color_accent};

  ${props => props.color && css `
    color: ${props => props.color};
  `}

  ${props => props.fontSize && css `
    font-size: ${props => props.fontSize};
  `}
`
