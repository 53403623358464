import React from 'react';
import styled, {css} from 'styled-components';
import { FeatureHeader } from '../StyleGuide/Atoms';
import * as styles from '../StyleGuide/Styles/Stylesheet.js';

const TestContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${styles.color_gry_1};
`

const TestWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: stretch;
  justify-content: center;
  padding: ${styles.spacing_standard};
  max-width: ${styles.width_mn};

  ${props => props.alignCenter && css`
    align-items: center;
  `}

  ${props => props.column && css`
    flex-direction: column;
  `}

  ${props => props.alignleft && css`
    justify-content: flex-start;
  `}

  ${props => props.narrow && css`
    max-width: ${styles.width_nrw};
  `}

  ${props => props.backgroundcolor && css`
    background-color: ${props => props.backgroundcolor};
  `}
`

class Test extends React.Component {

  componentDidMount() {
  }

  render() {
    return (
      <TestContainer>
        <FeatureHeader title="Testing Page" text="Testing new ideas on this page"/>
        <TestWrapper>
        </TestWrapper>
      </TestContainer>
    );
  }
}

export default Test;
