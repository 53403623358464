import "./radio.scss"
import React from "react"

const Radio = ({value, name, checked, onChange, label, disabled}) =>{

	return (
		<label className={`radio-wrapper${disabled ? ' disabled' : ''}`}>
			<input value={value} name={name} component="radio" type="radio" checked={checked} onChange={onChange} disabled={disabled} className="radio" />
			<span className="radio-circle"></span>
			{label}
		</label>
	)
}
 
export default Radio

 