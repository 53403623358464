import "./dropdown.scss"
import React, {useState, useEffect, useRef} from "react"

const Dropdown = ({text, label, error, reversed, disabled, items, onSelect, search}) =>{
	const ref = useRef()
    
	const [open, setOpen] = useState(false)
	const [selection, setSelection] = useState(text)
	const [dropdownHeight, setDropdownHeight] = useState(text)
    
	useEffect(() => {
		setDropdownHeight(items?.length * 48)
	}, [open])

	useEffect(() => {
		const closeDropdown = (e) => {
			if (open && ref.current && !ref.current.contains(e.target)) {
				setOpen(false)
			}
		}
		document.addEventListener("mousedown", closeDropdown)
		return () => {
			document.removeEventListener("mousedown", closeDropdown)
		}
	}, [open])

	const toggleDropdown = () => {
		setOpen(!open)
	}

	const selectHandler = (text, value) => {
		onSelect(text, value)
		setSelection(text)
		setOpen(false)
	}

	const styles = {
		height: {
			height: `${dropdownHeight}px`
		}
	}

	return (
		<div ref={ref} component="dropdown" className={`dropdown-wrapper${reversed ? ' reversed' : ''}${error ? ' error' : ''}${disabled ? ' disabled' : ''}${search ? ' search' : ''}`}>
			{label ? <label className="dropdown-label">{label}</label> : null }
			<div onClick={toggleDropdown} className={`dropdown-bar${open ? ' open' : ''}`}>
				{text ? text : selection}
				<div className="dropdown-icon-container">
					<div className="dropdown-icon fa fa-chevron-down"></div>
				</div>
			</div>
			<div className="dropdown-list">
				<div className={`dropdown-items${open ? ' open' : ''}`} style={open ? styles.height : null}>
					{items?.map((item, index) => {
						return(
							item.disabled ?
							<div key={index} className="dropdown-item disabled">{item.text}</div>
							:
							<div key={index} onClick={() => selectHandler(item.text, item.value)} className={`dropdown-item ${item.selected ? ' selected' : ''}`}>{item.text}</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}
 
export default Dropdown

 