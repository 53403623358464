import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseApp = initializeApp({
  apiKey: "AIzaSyCQeqLlFndC73tN8jEy8sw3JsO3tFaB0Bc",
  authDomain: "winn-website.firebaseapp.com",
  projectId: "winn-website",
  storageBucket: "winn-website.appspot.com",
  messagingSenderId: "432318795579",
  appId: "1:432318795579:web:825da331a70203c726311e",
  measurementId: "G-K218QD5JN8"
})

const auth = getAuth(firebaseApp)
const firestore = getFirestore(firebaseApp)

onAuthStateChanged(auth, user => {
  if (user != null) {
    console.log('logged in!')
  } else {
    console.log('No User')
  }
})

export default firestore;