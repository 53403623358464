import React from 'react';
import styled, { css } from 'styled-components';
import * as styles from '../Styles/Stylesheet.js';
import PropTypes from 'prop-types';

export const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`

export const DropdownIconContainer = styled.div`
  width: 48px;
  min-width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${styles.font_sans};
  background-color: ${styles.color_primary};
  color: ${styles.color_white};
  text-align: center;
  transition: all 250ms;

  ${props => props.open && css`
    background-color: ${styles.color_primary_dark};
  `}
`

export const DropdownBar = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid ${styles.color_gry_2};
  background-color: ${styles.color_white};
  transition: all 250ms;
  cursor: pointer;

  :hover {
    border: 1px solid ${styles.color_gry_3};
  }

  :hover ${DropdownIconContainer} {
    background-color: ${styles.color_primary_dark};
  }

  ${props => props.open && css`
    border: 1px solid ${styles.color_primary_dark};

    :hover {
      border: 1px solid ${styles.color_primary_dark};
    }
  `}
`

export const DropdownCopy = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: ${styles.spacing_standard_half} ${styles.container_standard_half};
  font-family: ${styles.font_sans};
  line-height: 1.2;
  color: ${styles.color_gry_6};
  text-align: center;
`

export const DropdownIcon = styled.i`
  font-size: 10px;
  transition: all 250ms;

  ${props => props.open && css`
    transform: rotate(90deg);
    transition: all 250ms;
  `}
`

export const DropdownItemContainer = styled.div`
  position: absolute;
  height: 0px;
  width: 100%;
  background-color: ${styles.color_white};
  border-left: 1px solid ${styles.color_gry_2};
  overflow-y: auto;
  transition: all 250ms;
  z-index: 5;

  ${props => props.open && css`
    height: ${props => props.dropdownHeight};
    border-bottom: 1px solid ${styles.color_gry_2};
    max-height: 270px;
    transition: all 250ms;

    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${styles.color_accent};
    }

    ::-webkit-scrollbar-track {
      background-color: ${styles.color_gry_2};
    }
  `}
`

export const DropdownItemWrapper = styled.div`
  border-bottom: 1px solid ${styles.color_gry_2};
  border-right: 1px solid ${styles.color_gry_2};

  :last-child {
    border-bottom: none;
  }
`

export const DropdownItem = styled.div`
  display: flex;
  height: auto;
  align-items: center;
  width: 100%;
  font-family: ${styles.font_sans};
  line-height: 1.2;
  padding: ${styles.container_standard_half};
  transition: all 250ms;
  cursor: pointer;

  :hover {
    background-color: ${styles.color_gry_1};
  }

  ${props => props.active && css`
    background-color: ${styles.color_gry_1};
    font-weight: ${styles.font_bold};
    color: ${styles.color_accent};
  `}

  ${props => props.disable && css`
    background-color: ${styles.color_white};
    color: ${styles.color_gry_2};
    cursor: not-allowed;

    :hover {
      background-color: ${styles.color_white};
    }
  `}
`

export class Dropdown extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      activeItem: false,
      dropdownItems: this.props.dropdownItems,
      dropdownCopy: this.props.dropdownCopy,
      dropdownHeight: '',
      identification: this.props.identification
    };
  }

  componentDidMount() {

    document.addEventListener("click", this.handleDropdown, false);
  
    var dropdownHeight = document.getElementById(this.state.identification).scrollHeight;

    if (dropdownHeight > 270) {
      dropdownHeight = 270
    }

    this.setState({
      dropdownHeight: dropdownHeight + 1 + "px"
    })

    window.addEventListener("resize", this.findDropdownHeight.bind(this));

    // Changes dropdown copy if the active item changes
    const dropdownItems = this.state.dropdownItems
    var activeItem = false

    Object.keys(dropdownItems).forEach((value) => {

      if (dropdownItems[value].active === true) {
        activeItem = true;
      }

    });
    this.setState({
      activeItem: activeItem
    })
  }

  handleDropdown = (e) => {
    if (this.node && this.node.contains(e.target)) {
      this.toggleDropdown();
      return;
    }

    this.closeDropdown();
  }

  toggleDropdown() {
    const dropdownState = this.state.open

    this.setState({
      open: !dropdownState
    })
  }

  closeDropdown() {
    this.setState({
      open: false
    })
  }

  findDropdownHeight() {
    if (document.getElementById(this.state.identification)) {
      var dropdownHeight = document.getElementById(this.state.identification).scrollHeight;
    } else {
      dropdownHeight = ''
    }

    if (dropdownHeight > 320) {
      dropdownHeight = 320
    }

    this.setState({
      dropdownHeight: dropdownHeight + 1 + "px"
    })
  }

  toggleDropdownSelection(selection) {
    const dropdownItems = this.state.dropdownItems
    var activeItem = this.state.dropdownCopy

    Object.keys(dropdownItems).forEach((value) => {

      if (dropdownItems[value].selection === selection) {
        dropdownItems[value].active = true;
        activeItem = dropdownItems[value].text;
      } else {
        dropdownItems[value].active = false;
      }

    });
    this.setState({
      dropdownItems: dropdownItems,
      dropdownCopy: activeItem
    })

    this.props.selection(selection)

  }

  render() {
    return (
      <DropdownContainer ref={node => this.node = node}>
        <DropdownBar open={this.state.open}>
          {this.state.activeItem ?
          <DropdownCopy>
            {Object.keys(this.state.dropdownItems).map((i, index) => {
              var activeItemCopy = ''
              var thisDropdown = this.state.dropdownItems[index]
              if (thisDropdown.active === true) {
                activeItemCopy = thisDropdown.text
              }
              return(
                <div key={index}>
                  {activeItemCopy}
                </div>
              )}
            )}
          </DropdownCopy> :
          <DropdownCopy>
            {this.state.dropdownCopy}
          </DropdownCopy>}
          <DropdownIconContainer open={this.state.open}>
            <DropdownIcon open={this.state.open} className="fa fa-chevron-down"></DropdownIcon>
          </DropdownIconContainer>
        </DropdownBar>
        <DropdownItemContainer id={this.state.identification} open={this.state.open} dropdownHeight={this.state.dropdownHeight}>
        {Object.keys(this.state.dropdownItems).map((i, index) => {
          var thisDropdown = this.state.dropdownItems[index]
          return(
            <DropdownItemWrapper key={index}>
            {thisDropdown.disable ?
            <DropdownItem key={index} disable>
              {thisDropdown.text}
            </DropdownItem> :
            <DropdownItem key={index} active={thisDropdown.active} onClick={() => {this.toggleDropdownSelection(thisDropdown.selection)}}>
              {thisDropdown.text}
            </DropdownItem>}
            </DropdownItemWrapper>
          )}
        )}
        </DropdownItemContainer>
      </DropdownContainer>
    );
  }
}

Dropdown.propTypes = {
  dropdownCopy: PropTypes.string,
  dropdownItems: PropTypes.array
}
