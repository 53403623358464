import React from 'react';
import styled, { css } from 'styled-components';
import * as styles from '../Styles/Stylesheet.js';

export const ColorsContainer = styled.div`
  width: 100%;
  max-width: ${styles.width_mn};
  display: flex;
  flex-wrap: wrap;
`

export const ColorsWrapper = styled.div`
  width: 16.66%;
  display: flex;
  flex-direction: column;
  padding: ${styles.spacing_standard_half};

  @media only screen and (max-width: ${styles.width_mn}) {
    width: 25%;
  }

  @media only screen and (max-width: ${styles.width_nrw}) {
    width: 50%;
  }

  @media only screen and (max-width: ${styles.width_sm}) {
    width: 100%;
  }
`

export const GrysWrapper = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  padding: ${styles.spacing_standard_half};

  @media only screen and (max-width: ${styles.width_nrw}) {
    width: 50%;
  }

  @media only screen and (max-width: ${styles.width_sm}) {
    width: 100%;
  }
`

export const Color = styled.div`
  width: 100%;
  height: 140px;
  border-top: 1px solid ${styles.color_gry_2};
  border-right: 1px solid ${styles.color_gry_2};
  border-left: 1px solid ${styles.color_gry_2};

  ${props => props.color && css `
    background-color: ${props => props.color};
  `}
`

export const ColorInformation = styled.div`
  width: 100%;
  padding: ${styles.spacing_standard_half};
  border: 1px solid ${styles.color_gry_2};
`

export const ColorHex = styled.div`
  width: 100%;
  font-family: ${styles.font_sans};
  line-height: 1;
  font-size: 14px;
  font-weight: ${styles.font_bold};
  padding: 0 0 5px 0;
`

export const ColorName = styled.div`
  width: 100%;
  font-family: ${styles.font_sans};
  line-height: 1;
  font-size: 12px;
  font-weight: ${styles.font_normal};
`

const Line = styled.div`
  width: 100%;
  border-top: 1px solid ${styles.color_gry_2};
  margin: ${styles.spacing_standard_half};
`

export class Colors extends React.Component {

  render() {
    return (
      <ColorsContainer>
        <ColorsWrapper>
          <Color color={styles.color_primary_light}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_primary_light}</ColorHex>
            <ColorName>color_primary_light</ColorName>
          </ColorInformation>
        </ColorsWrapper>
        <ColorsWrapper>
          <Color color={styles.color_primary}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_primary}</ColorHex>
            <ColorName>color_primary</ColorName>
          </ColorInformation>
        </ColorsWrapper>
        <ColorsWrapper>
          <Color color={styles.color_primary_dark}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_primary_dark}</ColorHex>
            <ColorName>color_primary_dark</ColorName>
          </ColorInformation>
        </ColorsWrapper>
        <ColorsWrapper>
          <Color color={styles.color_secondary_light}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_secondary_light}</ColorHex>
            <ColorName>color_secondary_light</ColorName>
          </ColorInformation>
        </ColorsWrapper>
        <ColorsWrapper>
          <Color color={styles.color_secondary}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_secondary}</ColorHex>
            <ColorName>color_secondary</ColorName>
          </ColorInformation>
        </ColorsWrapper>
        <ColorsWrapper>
          <Color color={styles.color_secondary_dark}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_secondary_dark}</ColorHex>
            <ColorName>color_secondary_dark</ColorName>
          </ColorInformation>
        </ColorsWrapper>
        <ColorsWrapper>
          <Color color={styles.color_accent_light}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_accent_light}</ColorHex>
            <ColorName>color_accent_light</ColorName>
          </ColorInformation>
        </ColorsWrapper>
        <ColorsWrapper>
          <Color color={styles.color_accent}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_accent}</ColorHex>
            <ColorName>color_accent</ColorName>
          </ColorInformation>
        </ColorsWrapper>
        <ColorsWrapper>
          <Color color={styles.color_accent_dark}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_accent_dark}</ColorHex>
            <ColorName>color_accent_dark</ColorName>
          </ColorInformation>
        </ColorsWrapper>
        <Line/>
        <ColorsWrapper>
          <Color color={styles.color_red}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_red}</ColorHex>
            <ColorName>color_red</ColorName>
          </ColorInformation>
        </ColorsWrapper>
        <ColorsWrapper>
          <Color color={styles.color_orange}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_orange}</ColorHex>
            <ColorName>color_orange</ColorName>
          </ColorInformation>
        </ColorsWrapper>
        <ColorsWrapper>
          <Color color={styles.color_yellow}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_yellow}</ColorHex>
            <ColorName>color_yellow</ColorName>
          </ColorInformation>
        </ColorsWrapper>
        <ColorsWrapper>
          <Color color={styles.color_green}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_green}</ColorHex>
            <ColorName>color_green</ColorName>
          </ColorInformation>
        </ColorsWrapper>
        <ColorsWrapper>
          <Color color={styles.color_blue}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_blue}</ColorHex>
            <ColorName>color_blue</ColorName>
          </ColorInformation>
        </ColorsWrapper>
        <ColorsWrapper>
          <Color color={styles.color_purple}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_purple}</ColorHex>
            <ColorName>color_purple</ColorName>
          </ColorInformation>
        </ColorsWrapper>
        <ColorsWrapper>
          <Color color={styles.color_red_dark}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_red_dark}</ColorHex>
            <ColorName>color_red_dark</ColorName>
          </ColorInformation>
        </ColorsWrapper>
        <ColorsWrapper>
          <Color color={styles.color_orange_dark}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_orange_dark}</ColorHex>
            <ColorName>color_orange_dark</ColorName>
          </ColorInformation>
        </ColorsWrapper>
        <ColorsWrapper>
          <Color color={styles.color_yellow_dark}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_yellow_dark}</ColorHex>
            <ColorName>color_yellow_dark</ColorName>
          </ColorInformation>
        </ColorsWrapper>
        <ColorsWrapper>
          <Color color={styles.color_green_dark}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_green_dark}</ColorHex>
            <ColorName>color_green_dark</ColorName>
          </ColorInformation>
        </ColorsWrapper>
        <ColorsWrapper>
          <Color color={styles.color_blue_dark}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_blue_dark}</ColorHex>
            <ColorName>color_blue_dark</ColorName>
          </ColorInformation>
        </ColorsWrapper>
        <ColorsWrapper>
          <Color color={styles.color_purple_dark}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_purple_dark}</ColorHex>
            <ColorName>color_purple_dark</ColorName>
          </ColorInformation>
        </ColorsWrapper>
        <Line/>
        <GrysWrapper>
          <Color color={styles.color_white}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_white}</ColorHex>
            <ColorName>color_white</ColorName>
          </ColorInformation>
        </GrysWrapper>
        <GrysWrapper>
          <Color color={styles.color_gry_1}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_gry_1}</ColorHex>
            <ColorName>color_gry_1</ColorName>
          </ColorInformation>
        </GrysWrapper>
        <GrysWrapper>
          <Color color={styles.color_gry_2}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_gry_2}</ColorHex>
            <ColorName>color_gry_2</ColorName>
          </ColorInformation>
        </GrysWrapper>
        <GrysWrapper>
          <Color color={styles.color_gry_3}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_gry_3}</ColorHex>
            <ColorName>color_gry_3</ColorName>
          </ColorInformation>
        </GrysWrapper>
        <GrysWrapper>
          <Color color={styles.color_gry_4}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_gry_4}</ColorHex>
            <ColorName>color_gry_4</ColorName>
          </ColorInformation>
        </GrysWrapper>
        <GrysWrapper>
          <Color color={styles.color_gry_5}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_gry_5}</ColorHex>
            <ColorName>color_gry_5</ColorName>
          </ColorInformation>
        </GrysWrapper>
        <GrysWrapper>
          <Color color={styles.color_gry_6}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_gry_6}</ColorHex>
            <ColorName>color_gry_6</ColorName>
          </ColorInformation>
        </GrysWrapper>
        <GrysWrapper>
          <Color color={styles.color_black}></Color>
          <ColorInformation>
            <ColorHex>{styles.color_black}</ColorHex>
            <ColorName>color_black</ColorName>
          </ColorInformation>
        </GrysWrapper>
      </ColorsContainer>
    );
  }
}
