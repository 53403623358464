import React from 'react';
import styled, { css } from 'styled-components';
import * as styles from '../Styles/Stylesheet.js';
import * as Atom from '../Atoms';

const NotesPopupWindow = styled.div`
  width: 100%;
  height: 100vh;
  min-height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: rgba(0,0,0,0.8);
  transform: translate(-50%, -50%);
  z-index: 9999;

  ${props => props.active && css `
    display: flex;
  `}
`

const NotesPopupContainer = styled.div`
  width: 100%;
  max-width: ${styles.width_nrw};
  padding: ${styles.spacing_standard};
  background-color: ${styles.color_white};
  box-shadow: ${styles.box_shadow};
`

const NotesWYSIWYG = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  width: 100%;
  height: 45px;
  background-color: ${styles.color_gry_1};
`

const NotesTypeSquare = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  background-color: ${styles.color_gry_1};
  cursor: pointer;
  transition: all 250ms;
  border-right: 1px solid ${styles.color_white};

  :hover {
    background-color: ${styles.color_gry_2};
  }
`

const NotesIcon = styled.i`
  font-weight: ${styles.font_bold};
  font-size: 14px;
  text-decoration: none;
  color: ${styles.color_gry_6};
`

const NotesButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const RightButton = styled.div`
  padding: 0 0 0 ${styles.spacing_standard_half};
`

export class NotesBlock extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      notesPopup: this.props.active,
      noteTitle: this.props.noteTitle
    };
  }

  closeNotesPopup() {
    this.setState({
      notesPopup: false
    })
  }

  openNotesPopup() {
    this.setState({
      notesPopup: true
    })
  }

  render() {

    return (
        <NotesPopupContainer>
          <NotesPopupWindow></NotesPopupWindow>
          <Atom.Input placeholder="Note Title" value={this.state.noteTitle} type="text"/>
          <NotesWYSIWYG>
            <NotesTypeSquare>
              <NotesIcon className="fa fa-bold"></NotesIcon>
            </NotesTypeSquare>
            <NotesTypeSquare>
              <NotesIcon className="fa fa-italic"></NotesIcon>
            </NotesTypeSquare>
            <NotesTypeSquare>
              <NotesIcon className="fa fa-underline"></NotesIcon>
            </NotesTypeSquare>
            <NotesTypeSquare>
              <NotesIcon className="fa fa-superscript"></NotesIcon>
            </NotesTypeSquare>
            <NotesTypeSquare>
              <NotesIcon className="fa fa-subscript"></NotesIcon>
            </NotesTypeSquare>
          </NotesWYSIWYG>
          <Atom.TextArea placeholder="Add your note here." />
          <NotesButtonsWrapper>
            <Atom.ButtonFilled text="save" />
            <RightButton onClick={() => {this.closeNotesPopup()}}>
              <Atom.ButtonFilled text="cancel" red="true" />
            </RightButton>
          </NotesButtonsWrapper>
        </NotesPopupContainer>
    );
  }
}
