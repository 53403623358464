import styled from 'styled-components';
import * as styles from '../Styles/Stylesheet.js';

export const h1 = styled.div`
  font-size: 52px;

  @media only screen and (max-width: ${styles.width_md}) {
    font-size: 40px;
  }
`

export const h2 = styled.div`
  font-size: 35px;
`

export const h3 = styled.div`
  font-size: 28px;
`

export const h4 = styled.div`
  font-size: 22px;
`

export const h5 = styled.div`
  font-size: 20px;
`
