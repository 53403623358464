import "./style-guide.scss";
import React, { useState } from "react";
import {
  Row,
  Column,
  ButtonFilled,
  ButtonOutline,
  Checkbox,
  Colors,
  Dropdown,
  FeatureHeader,
  HeaderBar,
  Input,
  Radio,
  Textarea,
  Body,
  Header,
  Footnote,
} from "../../StyleGuide/Atoms/index";
import { ImageZoom } from "../../StyleGuide/Molecules/index";
import { LogoAnimation } from "../../StyleGuide/Organisms/index";
import * as Organism from "../../StyleGuide/Organisms";
import cabin from "../../Media/Images/Cabin.jpg";
import newZealand from "../../Media/Images/NewZealand.jpg";
import snow from "../../Media/Images/Snow.jpg";

const StyleGuide = () => {
  const [selectedAtom, setSelectedAtom] = useState("Atoms");
  const [selectedMolecule, setSelectedMolecule] = useState("Molecules");
  const [selectedOrganism, setSelectedOrganism] = useState("Organisms");

  const atoms = [
    { text: "Button Filled", value: "button-filled", selected: false },
    { text: "Button Outline", value: "button-outline", selected: false },
    { text: "Checkbox", value: "checkbox", selected: false },
    { text: "Colors", value: "colors", selected: false },
    { text: "Dropdown", value: "dropDown", selected: false },
    { text: "Input", value: "input", selected: false },
    { text: "Radio", value: "radio", selected: false },
    { text: "Textarea", value: "text-area", selected: false },
    { text: "Typography", value: "typography", selected: false },
  ];

  const [atomsList, setAtomsList] = useState(atoms);

  const molecules = [
    { text: "Animations", value: "animations", selected: false },
    { text: "Image Zoom", value: "imageZoom", selected: false }
  ];

  const [moleculesList, setMoleculesList] = useState(molecules);

  const organisms = [
    { text: "Calculator", value: "calculator", selected: false },
    { text: "Character Sheet", value: "characterSheet", selected: false },
    { text: "Logo Animation", value: "logoAnimation", selected: false },
    { text: "Piano", value: "piano", selected: false },
  ];

  const [organismsList, setOrganismsList] = useState(organisms);

  const resetDropdownLists = () => {
    setAtomsList(atoms);
    setMoleculesList(molecules);
    setOrganismsList(organisms);
  };

  const atomsHandler = (text) => {
    setSelectedAtom(text);
    setSelectedMolecule("Molecules");
    setSelectedOrganism("Organisms");

    resetDropdownLists();
    const newAtomsList = atomsList.map((item) => {
      if (item.text === text) {
        return { ...item, selected: true };
      } else {
        return { ...item, selected: false };
      }
    });
    setAtomsList(newAtomsList);
  };

  const moleculesHandler = (text) => {
    setSelectedAtom("Atoms");
    setSelectedMolecule(text);
    setSelectedOrganism("Organisms");

    resetDropdownLists();
    const newMoleculesList = moleculesList.map((item) => {
      if (item.text === text) {
        return { ...item, selected: true };
      } else {
        return { ...item, selected: false };
      }
    });
    setMoleculesList(newMoleculesList);
  };

  const organismsHandler = (text) => {
    setSelectedAtom("Atoms");
    setSelectedMolecule("Molecules");
    setSelectedOrganism(text);

    resetDropdownLists();
    const newOrganismsList = organismsList.map((item) => {
      if (item.text === text) {
        return { ...item, selected: true };
      } else {
        return { ...item, selected: false };
      }
    });
    setOrganismsList(newOrganismsList);
  };

  // COMPONENTS HANDLERS

  const checkboxHandler = () => {
    setCheckbox(!checkbox);
  };

  const [dropdown, setDropdown] = useState("Dropdown");
  const dropdownHandler = (text) => {
    setDropdown(text);
  };

  const [checkbox, setCheckbox] = useState(true);

  return (
    <div className="style-guide">
      <FeatureHeader title="Style Guide" text="a cozy home for my components" />
      <div className="options-container">
        <div className="options-wrapper">
          <div className="option">
            <Dropdown
              text={selectedAtom}
              selected={selectedAtom}
              onSelect={atomsHandler}
              items={atomsList}
            />
          </div>
          <div className="option">
            <Dropdown
              text={selectedMolecule}
              onSelect={moleculesHandler}
              items={moleculesList}
            />
          </div>
          <div className="option">
            <Dropdown
              text="Organisms"
              onSelect={organismsHandler}
              items={organismsList}
            />
          </div>
        </div>
      </div>

      {selectedAtom === "Button Filled" ? (
        <div className="component-container">
          <HeaderBar text={selectedAtom} />
          <div className="component-wrapper">
            <ButtonFilled text="button filled default" />
          </div>
          <div className="component-wrapper">
            <ButtonFilled color="secondary" text="button filled secondary" />
          </div>
          <div className="component-wrapper">
            <ButtonFilled color="accent" text="button filled accent" />
          </div>
          <div className="component-wrapper reversed">
            <ButtonFilled reversed text="button filled reversed" />
          </div>
          <div className="component-wrapper">
            <ButtonFilled disabled text="button filled disabled" />
          </div>
        </div>
      ) : null}

      {selectedAtom === "Button Outline" ? (
        <div className="component-container">
          <HeaderBar text={selectedAtom} />
          <div className="component-wrapper">
            <ButtonOutline text="button outline default" />
          </div>
          <div className="component-wrapper">
            <ButtonOutline color="secondary" text="button outline secondary" />
          </div>
          <div className="component-wrapper">
            <ButtonOutline color="accent" text="button outline accent" />
          </div>
          <div className="component-wrapper reversed">
            <ButtonOutline reversed text="button outline reversed" />
          </div>
          <div className="component-wrapper">
            <ButtonOutline disabled text="button outline disabled" />
          </div>
        </div>
      ) : null}

      {selectedAtom === "Checkbox" ? (
        <div className="component-container">
          <HeaderBar text={selectedAtom} />
          <div className="component-wrapper narrow">
            <Checkbox label="Checkbox Default" />
            <Checkbox
              label="Checkbox Checked"
              checked={checkbox}
              onChange={checkboxHandler}
            />
            <Checkbox label="Checkbox Disabled" disabled />
            <Checkbox label="Checkbox Disabled Checked" disabled checked />
          </div>
        </div>
      ) : null}

      {selectedAtom === "Colors" ? (
        <div className="component-container">
          <HeaderBar text={selectedAtom} />
          <div className="component-wrapper">
            <Colors />
          </div>
        </div>
      ) : null}

      {selectedAtom === "Dropdown" ? (
        <div className="component-container">
          <HeaderBar text={selectedAtom} />
          <div className="component-wrapper narrow">
            <Dropdown
              text={dropdown}
              onSelect={dropdownHandler}
              items={[
                { text: "Option 1", value: "option-1" },
                { text: "Option 2", value: "option-2" },
                { text: "Option 3", value: "option-3" },
                { text: "Option 4", value: "option-4" },
                { text: "Option 5", value: "option-5" },
                { text: "Option 6", value: "option-6" },
              ]}
            />
          </div>
          <div className="component-wrapper narrow">
            <Dropdown text="Disabled" disabled />
          </div>
        </div>
      ) : null}

      {selectedAtom === "Input" ? (
        <div className="component-container">
          <HeaderBar text={selectedAtom} />
          <div className="component-wrapper narrow">
            <Input
              label="Default Input Field"
              placeholder="Default Input Field"
            />
          </div>
          <div className="component-wrapper narrow">
            <Input
              type="password"
              label="Password Input Field"
              placeholder="Password Input Field"
            />
          </div>
          <div className="component-wrapper narrow">
            <Input
              type="number"
              label="Number Input Field"
              placeholder="Number Input Field"
            />
          </div>
          <div className="component-wrapper narrow">
            <Input
              error
              label="Error Input Field"
              placeholder="Error Input Field"
              explainer="Explainer Copy"
            />
          </div>
          <div className="component-wrapper narrow">
            <Input
              label="Disabled Input Field"
              placeholder="Disabled Input Field"
              disabled
            />
          </div>
          <div className="component-wrapper narrow reversed">
            <Input
              label="Reversed Input Field"
              placeholder="Reversed Input Field"
              explainer="Explainer Copy"
              reversed
            />
          </div>
        </div>
      ) : null}

      {selectedAtom === "Radio" ? (
        <div className="component-container">
          <HeaderBar text={selectedAtom} />
          <div className="component-wrapper narrow">
            <Radio value="Example 1" name="example" label="Option 1" />
            <Radio value="Example 2" name="example" label="Option 2" />
            <Radio value="Example 3" name="example" label="Disabled" disabled />
            <Radio value="Example 4" name="example" label="Option 4" />
          </div>
        </div>
      ) : null}

      {selectedAtom === "Textarea" ? (
        <div className="component-container">
          <HeaderBar text={selectedAtom} />
          <div className="component-wrapper narrow">
            <Textarea
              label="Default Input Field"
              placeholder="Default Input Field"
            />
          </div>
          <div className="component-wrapper narrow">
            <Textarea
              error
              label="Error Input Field"
              placeholder="Error Input Field"
              explainer="Explainer Copy"
            />
          </div>
          <div className="component-wrapper narrow">
            <Textarea
              label="Disabled Input Field"
              placeholder="Disabled Input Field"
              disabled
            />
          </div>
          <div className="component-wrapper narrow reversed">
            <Textarea
              label="Reversed Input Field"
              placeholder="Reversed Input Field"
              explainer="Explainer Copy"
              reversed
            />
          </div>
        </div>
      ) : null}

      {selectedAtom === "Typography" ? (
        <div className="component-container">
          <HeaderBar text={selectedAtom} />
          <div className="component-wrapper narrow">
            <Header type="h1">Header H1</Header>
            <Header type="h2">Header H2</Header>
            <Header type="h3">Header H3</Header>
            <Header type="h4">Header H4</Header>
            <Header type="h5">Header H5</Header>
            <Body>
              <p>
                Body Copy Example: Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Nulla pretium egestas feugiat. Pellentesque
                mattis eu orci quis bibendum. Morbi tempor diam at massa
                efficitur scelerisque. Donec lacinia aliquam ex. Mauris interdum
                purus massa, in hendrerit ipsum vulputate eget. Ut pharetra odio
                at lacus fringilla tempor. Integer ut odio in nisl aliquam
                feugiat a eu arcu. In enim tortor, ultricies quis elit vitae,
                sodales ullamcorper nulla. Donec tellus augue, efficitur et odio
                ac, tristique sodales velit. Vestibulum eu arcu id nulla
                molestie convallis. Ut purus orci, aliquam sit amet tristique
                sit amet, scelerisque quis neque. Nunc egestas lacus felis, in
                finibus purus eleifend sed. Etiam vitae neque eu nunc pulvinar
                aliquam.
              </p>
              <ul>
                <li>Unordered List</li>
                <li>List Item 1</li>
                <li>
                  List Item 1
                  <ul>
                    <li>Second Tier</li>
                    <li>List Item 2</li>
                    <li>
                      List Item 2
                      <ul>
                        <li>Third Tier</li>
                        <li>List Item 3</li>
                        <li>List Item 3</li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
              <ol>
                <li>Ordered List</li>
                <li>List Item 1</li>
                <li>
                  List Item 1
                  <ul>
                    <li>Second Tier</li>
                    <li>List Item 2</li>
                    <li>
                      List Item 2
                      <ul>
                        <li>Third Tier</li>
                        <li>List Item 3</li>
                        <li>List Item 3</li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ol>
            </Body>
            <Footnote center>Footnote text</Footnote>
          </div>
        </div>
      ) : null}

      {selectedMolecule === "Image Zoom" ? (
        <div className="component-container">
          <HeaderBar text={selectedMolecule} />
          <Footnote align="center">Photos by Ben White</Footnote>
          <div className="component-wrapper">
            <Row spacing={16}>
              <Column xs={24} md={8}>
                <ImageZoom image={cabin} />
              </Column>
              <Column xs={24} md={8}>
                <ImageZoom image={newZealand} />
              </Column>
              <Column xs={24} md={8}>
                <ImageZoom image={snow} />
              </Column>
            </Row>
          </div>
        </div>
      ) : null}

      {selectedOrganism === "Character Sheet" ? (
        <div className="component-container">
          <HeaderBar text={selectedOrganism} />
          <div className="component-wrapper">
            <Organism.CharacterSheet />
          </div>
        </div>
      ) : null}

      {selectedOrganism === "Calculator" ? (
        <div className="component-container">
          <HeaderBar text={selectedOrganism} />
          <div className="component-wrapper">
            <Organism.Calculator />
          </div>
        </div>
      ) : null}

      {selectedOrganism === "Piano" ? (
        <div className="component-container">
          <HeaderBar text={selectedOrganism} />
          <div className="component-wrapper">
            <Organism.Piano />
          </div>
        </div>
      ) : null}

      {selectedOrganism === "Logo Animation" ? (
        <div className="component-container">
          <HeaderBar text={selectedOrganism} />
          <Footnote align="center">Click logo to replay</Footnote>
          <div className="component-wrapper narrow">
            <LogoAnimation />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default StyleGuide;
