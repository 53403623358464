import "./typography.scss"
import React from "react"
import variables from "../../Styles/variables.scss";

export const Body = ({children}) =>{

	return (
		<div className="body">{children}</div>
	)
}

export const Header = ({type, text, children, align, color}) =>{

	if (!type) {type = 'h1'}
	if (!color) {color = variables.accent}
	if (!align) {align = 'left'}
	
	const styles = {
		header: {
			color: color,
			textAlign: align
		}
	}

	return (
		<>
		{type === 'h1' ? 
		<h1 style={styles.header}>{children ? children : text}</h1>
		: type === 'h2' ?
		<h2 style={styles.header}>{children ? children : text}</h2>
		: type === 'h3' ?
		<h3 style={styles.header}>{children ? children : text}</h3>
		: type === 'h4' ?
		<h4 style={styles.header}>{children ? children : text}</h4>
		: type === 'h5' ?
		<h5 style={styles.header}>{children ? children : text}</h5>
		: null}
		</>
	)
}

export const Footnote = ({align, children}) =>{

	if (!align) {align = 'left'}
	
	const styles = {
		footnote: {
			textAlign: align
		}
	}

	return (
		<div className="footnote" style={styles.footnote}>{children}</div>
	)
}