import React from 'react';
import styled from 'styled-components';
import { FeatureHeader } from '../StyleGuide/Atoms';
import * as styles from '../StyleGuide/Styles/Stylesheet.js';

const NotFoundPageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-contnet: center;
  width: 100%;
  height: calc(100vh - 79px);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media only screen and (max-width: ${styles.width_lg}) {
    height: calc(100vh - 69px);
  }
`

const NotFoundPageWrapper = styled.div`
  width: 100%;
`

class NotFoundPage extends React.Component {

  render() {

    return (
      <NotFoundPageContainer>
        <NotFoundPageWrapper>
          <FeatureHeader title="404" text="This page does not exist." />
        </NotFoundPageWrapper>
      </NotFoundPageContainer>
    );
  }
}

export default NotFoundPage;
