import React from 'react';
import styled, {css} from 'styled-components';
import * as styles from '../Styles/Stylesheet.js';

const LogoAnimationContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LogoAnimationWrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const LogoAnimationBox = styled.div`
  position: relative;
  width: 200px;
  height: 0px;
  overflow: hidden;
  transition: 500ms all;
  opacity: 0;

  ${props => props.resize && css`
    height: 44px;
    opacity: 1;
  `}
`

const LogoAnimationBarOne = styled.div`
  position: absolute;
  left: 0;
  width: 18px;
  height: 44px;
  background-color: ${styles.color_primary};
  transition: 500ms all;
  transition-timing-function: ease;
  z-index: 1;

  ${props => props.skew && css`
    left: 13px;
    transform: skew(-30.5deg);
  `}
`

const LogoAnimationBarTwo = styled.div`
  position: absolute;
  left: 26px;
  width: 18px;
  height: 44px;
  background-color: ${styles.color_primary};
  transition: 500ms all;
  transition-timing-function: ease;
  z-index: 1;

  ${props => props.skew && css`
    left: 39px;
    transform: skew(-30.5deg);
  `}
`

const LogoAnimationBarThree = styled.div`
  position: absolute;
  left: 52px;
  width: 18px;
  height: 44px;
  background-color: ${styles.color_primary};
  transition: 500ms all;
  transition-timing-function: ease;
  z-index: 1;

  ${props => props.skew && css`
    left: 65px;
    transform: skew(-30.5deg);
    background-color: ${styles.color_secondary};
  `}
`

const LogoAnimationBarFour = styled.div`
  position: absolute;
  left: 78px;
  width: 18px;
  height: 44px;
  background-color: ${styles.color_primary};
  transition: 500ms all;
  transition-timing-function: ease;
  z-index: 1;

  ${props => props.skew && css`
    left: 91px;
    transform: skew(-30.5deg);
  `}
`

const LogoAnimationBarFive = styled.div`
  position: absolute;
  left: 104px;
  width: 18px;
  height: 44px;
  background-color: ${styles.color_primary};
  transition: 500ms all;
  transition-timing-function: ease;
  z-index: 1;

  ${props => props.skew && css`
    left: 117px;
    transform: skew(-30.5deg);
  `}
`

const LogoAnimationBarSix = styled.div`
  position: absolute;
  left: 130px;
  width: 18px;
  height: 44px;
  background-color: ${styles.color_primary};
  transition: 500ms all;
  transition-timing-function: ease;
  z-index: 1;

  ${props => props.skew && css`
    left: 143px;
    transform: skew(-30.5deg);
  `}
`

const LogoAnimationBarSeven = styled.div`
  position: absolute;
  left: 156px;
  width: 18px;
  height: 44px;
  background-color: ${styles.color_primary};
  transition: 500ms all;
  transition-timing-function: ease;
  z-index: 1;

  ${props => props.skew && css`
    left: 169px;
    transform: skew(-30.5deg);
  `}
`

const LogoAnimationBarEight = styled.div`
  position: absolute;
  left: -26px;
  width: 18px;
  height: 44px;
  background-color: ${styles.color_primary};
  transition: 500ms all;
  transition-timing-function: ease;
  z-index: 1;

  ${props => props.skew && css`
    left: -13px;
    transform: skew(-30.5deg);
  `}
`

const LogoAnimationBackgroundBarOne = styled.div`
  position: absolute;
  left: 0;
  width: 18px;
  height: 44px;
  background-color: ${styles.color_primary_dark};
`

const LogoAnimationBackgroundBarTwo = styled.div`
  position: absolute;
  left: 26px;
  width: 18px;
  height: 44px;
  background-color: ${styles.color_primary_dark};
`

const LogoAnimationBackgroundBarThree = styled.div`
  position: absolute;
  left: 52px;
  width: 18px;
  height: 44px;
  background-color: ${styles.color_gry_1};
`

const LogoAnimationBackgroundBarFour = styled.div`
  position: absolute;
  left: 78px;
  width: 18px;
  height: 44px;
  background-color: ${styles.color_gry_1};
`

const LogoAnimationBackgroundBarFive = styled.div`
  position: absolute;
  left: 104px;
  width: 18px;
  height: 44px;
  background-color: ${styles.color_primary_dark};
`

const LogoAnimationBackgroundBarSix = styled.div`
  position: absolute;
  left: 130px;
  width: 18px;
  height: 44px;
  background-color: ${styles.color_gry_1};
`

const LogoAnimationBackgroundBarSeven = styled.div`
  position: absolute;
  left: 156px;
  width: 18px;
  height: 44px;
  background-color: ${styles.color_primary_dark};
`

export class LogoAnimation extends React.Component {

  constructor() {
    super();
    this.state = {
      animation: true,
      resizeLogoWrapper: false,
      skew: false,
    };
  }

  componentDidMount() {

    setTimeout(() => {
      this.resizeLogoWrapper();
    }, 500);

    setTimeout(() => {
      this.skew();
    }, 1000);

  }

  resizeLogoWrapper() {
    this.setState({
      resizeLogoWrapper: true
    })
  }

  skew() {
    this.setState({
      skew: true
    })
  }

  replayAnimation() {

    this.setState({
      animation: false,
      resizeLogoWrapper: false,
      skew: false,
    }, () => {
      setTimeout(() => {
        this.setState({
          animation: true
        })
        
        setTimeout(() => {
          this.resizeLogoWrapper();
        }, 500);

        setTimeout(() => {
          this.skew();
        }, 1000);
      }, 100);
    })
  }

  render() {
    return (
      <LogoAnimationContainer>
        <LogoAnimationWrapper>
          {this.state.animation ? <LogoAnimationBox onClick={() => this.replayAnimation()} resize={this.state.resizeLogoWrapper}>
            <LogoAnimationBarOne skew={this.state.skew} />
            <LogoAnimationBarTwo skew={this.state.skew} />
            <LogoAnimationBarThree skew={this.state.skew} />
            <LogoAnimationBarFour skew={this.state.skew} />
            <LogoAnimationBarFive skew={this.state.skew} />
            <LogoAnimationBarSix skew={this.state.skew} />
            <LogoAnimationBarSeven skew={this.state.skew} />
            <LogoAnimationBarEight skew={this.state.skew} />
            <LogoAnimationBackgroundBarOne />
            <LogoAnimationBackgroundBarTwo />
            <LogoAnimationBackgroundBarThree />
            <LogoAnimationBackgroundBarFour />
            <LogoAnimationBackgroundBarFive />
            <LogoAnimationBackgroundBarSix />
            <LogoAnimationBackgroundBarSeven />
          </LogoAnimationBox> : null}
        </LogoAnimationWrapper>
      </LogoAnimationContainer>
    );
  }
}
