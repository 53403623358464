import React from 'react';
import styled from 'styled-components';
import * as styles from '../Styles/Stylesheet.js';

export const SectionTextContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 ${styles.spacing_standard_half} 0;
  margin: 0 ${styles.spacing_standard_half};
`

const SectionTextRule = styled.span`
  width: 100%;
  background-color: ${styles.color_accent};
  opacity: 0.3;
  height: 1px;
`

const SectionTextWrapper = styled.div`
  flex: 1 0 auto;
  padding: 0 ${styles.spacing_standard};
  color: ${styles.color_accent};
  font-family: ${styles.font_sans};
  line-height: 1.1;
  text-align: center;
  font-weight: ${styles.font_normal};
  font-size: 14px;
`

export class SectionText extends React.Component {

  render() {
    return (
      <SectionTextContainer id={this.props.id}>
        <SectionTextRule>
        </SectionTextRule>
        <SectionTextWrapper>
          {this.props.text}
        </SectionTextWrapper>
        <SectionTextRule>
        </SectionTextRule>
      </SectionTextContainer>
    );
  }
}
