import React from 'react';
import styled from 'styled-components';
import { FeatureHeader } from '../StyleGuide/Atoms';
import * as styles from '../StyleGuide/Styles/Stylesheet.js';
import * as Organism from '../StyleGuide/Organisms';

const PianoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${styles.color_gry_1};
`

const PianoWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 400px);
  max-width: ${styles.width_mn};
  padding: 40px ${styles.spacing_standard};
  margin: 0 auto;
`

class Piano extends React.Component {

  render() {

    return (
      <PianoContainer>
        <FeatureHeader title="Piano" text="I built this piano in my free time for fun. Click on a key or use your keyboard to play." />
        <PianoWrapper>
          <Organism.Piano />
        </PianoWrapper>
      </PianoContainer>
    );
  }
}

export default Piano;
