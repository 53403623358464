import React from 'react';
import styled, { css } from 'styled-components';
import * as styles from '../Styles/Stylesheet.js';

const TextAreaContainer = styled.textarea`
  position: relative;
  width: 100%;
  min-height: 100px;
  padding: ${styles.container_standard_half};
  margin: 0 0 ${styles.spacing_standard} 0;
  font-family: ${styles.font_sans};
  font-size: 16px;
  color: ${styles.color_gry_6};
  line-height: 1;
  background-color: ${styles.color_white};
  border: 1px solid ${styles.color_gry_2};
  border-radius: 0px;
  box-shadow: none;
  cursor: text;
  outline: 0;
  transition: all 100ms;
  resize: vertical;
  vertical-align: top;
  -webkit-appearance: none;

  :hover {
    border: 1px solid ${styles.color_gry_3};
  }

  ${props => props.error && css `
    background-color: ${styles.color_gry_1};
    border: 1px solid ${styles.color_red};

    :hover {
      border: 1px solid ${styles.color_red};
    }
  `}

  ${props => props.active && css `
    background-color: ${styles.color_white};
    border: 1px solid ${styles.color_primary};

    :hover {
      border: 1px solid ${styles.color_primary};
    }
  `}
`

export class TextArea extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      active: false,
      value: ''
    };
  }

  componentWillMount() {
    document.addEventListener("click", this.handleState.bind(this), false);
  }

  handleState(e) {
    if (this.node && this.node.contains(e.target)) {
      this.activeInput();
      return;
    }

    this.inactiveInput();
  }

  activeInput(value) {
    this.setState({
      active: true
    })

  }

  inactiveInput() {
    this.setState({
      active: false
    })
  }

  render() {

    return (
      <TextAreaContainer name={this.props.name} ref={node => this.node = node} onKeyUp={this.props.onKeyUp} onBlur={() => {this.inactiveInput()}} type={this.props.type} error={this.props.error} active={this.state.active} placeholder={this.props.placeholder}/>
    );
  }
}
