import "./button-filled.scss"
import React, {useState} from "react"
import variables from "../../Styles/variables.scss";

const ButtonFilled = ({text, color, hoverColor, reversed, disabled, large, wide}) =>{

    if (!color) {color = variables.primary;}
    if (color === 'secondary') {color = variables.secondary; hoverColor = variables.secondaryDark}
    if (color === 'accent') {color = variables.accent; hoverColor = variables.accentDark}
    if (color === 'red') {color = variables.red; hoverColor = variables.redDark}

    if (!hoverColor) {hoverColor = variables.primaryDark;}

    const [isFocused, setFocus] = useState(false);

    const styles = {
        normal: {
            backgroundColor: isFocused ? hoverColor : color,
            borderBottom: `3px solid ${hoverColor}`,
        },
        reversed: {
            color: isFocused ? variables.white : color,
            backgroundColor:  isFocused ? 'transparent' : variables.white,
        }
    }

    return (
        <button component="button-filled" onMouseEnter={() => setFocus(true)} onMouseLeave={() => setFocus(false)} className={`button-filled${reversed ? ' reversed' : ''}${disabled ? ' disabled' : ''}${large ? ' large' : ''}${wide ? ' wide' : ''}`} style={disabled ? null : (reversed ? styles.reversed : styles.normal) }>{text}</button>
    )
}
 
export default ButtonFilled

 