import "./input.scss"
import React from "react"

const Input = ({type, onChange, onClick, onKeyUp, value, label, placeholder, error, explainer, reversed, disabled}) =>{

	if (!type) {
		type = 'text'
	}

	return (
		<div className={`input-wrapper${reversed ? ' reversed' : ''}${error ? ' error' : ''}`}>
			{label ? <label className="input-label">{label}</label> : null }
			<input component="input" type={type} value={value} placeholder={placeholder} onClick={onClick} onKeyUp={onKeyUp} onChange={onChange} disabled={disabled} className="input" />
			{explainer ? <div className="input-explainer">{explainer}</div> : null }
		</div>
	)
}
 
export default Input

 