import "./header-bar.scss"
import React from "react"

const HeaderBar = ({text}) =>{

    return (
      <div className="header-bar">
        <div className="header-bar-title">
          {text}
        </div>
      </div>
    )
}
 
export default HeaderBar

 