import "./button-outline.scss"
import React, {useState} from "react"
import variables from "../../Styles/variables.scss";

const ButtonOutline = ({text, color, reversed, disabled, large, wide}) =>{

    if (!color) {color = variables.primary;}
    if (color === 'secondary') {color = variables.secondary}
    if (color === 'accent') {color = variables.accent}
    if (color === 'red') {color = variables.red}

    const [isFocused, setFocus] = useState(false);

    const styles = {
        normal: {
            backgroundColor: isFocused ? color : 'transparent',
            color: isFocused ? variables.white : color,
            border: `3px solid ${color}`,
        },
        reversed: {
            color: isFocused ? variables.gry6 : variables.white,
            backgroundColor:  isFocused ? variables.white : 'transparent',
        }
    }

    return (
        <button component="button-outline" onMouseEnter={() => setFocus(true)} onMouseLeave={() => setFocus(false)} className={`button-outline${reversed ? ' reversed' : ''}${disabled ? ' disabled' : ''}${large ? ' large' : ''}${wide ? ' wide' : ''}`} style={disabled ? null : (reversed ? styles.reversed : styles.normal) }>{text}</button>
    )
}
 
export default ButtonOutline

 