import React from 'react';
import styled, { css } from 'styled-components';
import * as styles from '../Styles/Stylesheet.js';

export const TagContainer = styled.div`
  padding: 0 0 ${styles.spacing_standard_half} 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const TagWrapper = styled.div`
  margin: 0 ${styles.container_standard_half} ${styles.spacing_standard_half} 0;
  display: flex;
  align-items: center;
  font-family: ${styles.font_sans};
  font-size: 12px;
  font-weight: ${styles.font_bold};
  text-transform: uppercase;
  text-decoration: none;
  color: ${styles.color_gry_5};
  line-height: 1;
  transition: all 250ms;

  ${props => props.line && css `
    padding: 0 ${styles.container_standard_half} 0 0;
    border-right: 1px solid ${styles.color_gry_2};
  `}

  ${props => props.active && css `
    cursor: pointer;
    color: ${styles.color_primary};

    :hover {
      color: ${styles.color_primary_dark};
    }
  `}

  ${props => props.primary && css `
    background-color: ${styles.color_primary};
    color: ${styles.color_white};
    padding: 8px 10px;
    margin: 0 10px 10px 0;
    border-radius: 5px;

    ${props => props.active && css `
      cursor: pointer;

      :hover {
        background-color: ${styles.color_primary_dark};
      }
    `}
  `}

  ${props => props.secondary && css `
    background-color: ${styles.color_secondary};
    color: ${styles.color_white};
    padding: 8px 10px;
    margin: 0 10px 10px 0;
    border-radius: 5px;
  `}

  ${props => props.accent && css `
    background-color: ${styles.color_accent};
    color: ${styles.color_white};
    padding: 8px 10px;
    margin: 0 10px 10px 0;
    border-radius: 5px;
  `}

  ${props => props.red && css `
    background-color: ${styles.color_red};
    color: ${styles.color_white};
    padding: 8px 10px;
    margin: 0 10px 10px 0;
    border-radius: 5px;
  `}

  ${props => props.green && css `
    background-color: ${styles.color_green};
    color: ${styles.color_white};
    padding: 8px 10px;
    margin: 0 10px 10px 0;
    border-radius: 5px;
  `}

  ${props => props.white && css `
    background-color: ${styles.color_white};
    color: ${styles.color_accent};
    padding: 8px 10px;
    margin: 0 10px 10px 0;
    border-radius: 5px;
  `}

  ${props => props.reversed && css `
    color: ${styles.color_white};
  `}
`

export const TagIcon = styled.i`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px 0 0;
  font-size: 14px;
  color: ${styles.color_white};

  ${props => props.white && css `
    color: ${styles.color_accent};
  `}
`

export class Tags extends React.Component {

  tagIdentification(identification) {
    this.props.identification(identification)
  }

  render() {
    return (
      <TagContainer>
        {Object.keys(this.props.tagItems).map((i, index) => {
          var thisTag = this.props.tagItems[index]
          const tagIcon = thisTag.icon
          return(
            <TagWrapper active={thisTag.active} line={thisTag.line} primary={thisTag.primary} secondary={thisTag.secondary} accent={thisTag.accent} red={thisTag.red} green={thisTag.green} white={thisTag.white} reversed={thisTag.reversed} onClick={() => {this.tagIdentification(thisTag.identification)}}>
            {thisTag.icon ? <TagIcon white={thisTag.white} className={`fa ${tagIcon}`}></TagIcon> : null}
              {thisTag.text}
            </TagWrapper>
          )}
        )}
      </TagContainer>
    );
  }
}
