import React from 'react';
import styled, { css } from 'styled-components';
import * as styles from '../Styles/Stylesheet.js';

export const RadioOutline = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: ${styles.color_white};
  border: 2px solid ${styles.color_gry_2};
  border-radius: 20px;
  transition: all 100ms;

  &:after {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: ${styles.color_white};
    transition: all 100ms;
  }
`

export const RadioText = styled.span`
  width: 100%;
  padding: 0 12px;
  font-family: ${styles.font_sans};
  font-size: 16px;
  font-weight: ${styles.font_normal};
  color: ${styles.color_gry_6};
  line-height: 1.6;

  ${props => props.disable && css `
    color: ${styles.color_gry_3};
  `}
`

export const RadioInput = styled.input`
  display: none;

  &:checked + ${RadioOutline} {
    border: 2px solid ${styles.color_accent};
  }

  &:checked + ${RadioOutline}:after {
    background-color: ${styles.color_accent};
    animation: sizing 100ms;

    @keyframes sizing {
      0% {
        transform: scale(1.0);
      }
      80% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(1.0);
      }
    }
  }
`

export const RadioWrapper = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  padding: 0 0 ${styles.spacing_standard_half} 0;
  cursor: pointer;

  &:hover ${RadioOutline} {
    border: 2px solid ${styles.color_gry_3};
    transition: all 100ms;
  }

  &:hover ${RadioOutline}:after {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: ${styles.color_gry_2};
    transition: all 100ms;
  }

  &:hover ${RadioInput}:checked + ${RadioOutline} {
    border: 2px solid ${styles.color_accent};
  }

  &:hover ${RadioInput}:checked + ${RadioOutline}:after {
    background-color: ${styles.color_accent};
  }

  ${props => props.disable && css `
    cursor: not-allowed;

    &:hover ${RadioOutline} {
      border: 2px solid ${styles.color_gry_2};
      transition: all 100ms;
    }

    &:hover ${RadioOutline}:after {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: ${styles.color_white};
      transition: all 100ms;
    }
  `}
`

export class Radio extends React.Component {

  render() {
    return (
      <RadioWrapper disable={this.props.disable}>
        <RadioInput type="radio" name={this.props.name} disabled={this.props.disable} defaultChecked={this.props.checked}>
        </RadioInput>
        <RadioOutline id={this.props.identification} checked={this.props.checked}>
        </RadioOutline>
        <RadioText disable={this.props.disable}>{this.props.text}</RadioText>
      </RadioWrapper>
    );
  }
}
