export const color_primary_light = '#6bd2fc';
export const color_primary = '#28a1ea';
export const color_primary_dark = '#006db7';

export const color_secondary_light = '#f950ad';
export const color_secondary = '#ef258a';
export const color_secondary_dark = '#bc1772';

export const color_accent_light = '#2f617f';
export const color_accent = '#193c56';
export const color_accent_dark = '#0d273d';

export const color_white = '#ffffff';
export const color_gry_1 = '#f1f1f1';
export const color_gry_2 = '#dedede';
export const color_gry_3 = '#bcbcbc';
export const color_gry_4 = '#888888';
export const color_gry_5 = '#505050';
export const color_gry_6 = '#222222';
export const color_black = '#000000';

export const color_red = '#fc5151';
export const color_red_dark = '#e52828';
export const color_orange = '#f99b3e';
export const color_orange_dark = '#ea711f';
export const color_yellow = '#fffa50';
export const color_yellow_dark = '#e8d700';
export const color_green = '#56d156';
export const color_green_dark = '#2cb42c';
export const color_blue = '#58cff9';
export const color_blue_dark = '#20aad3';
export const color_purple = '#b06cff';
export const color_purple_dark = '#7e48e5';

export const width_xs = "360px";
export const width_sm = "480px";
export const width_md = "640px";
export const width_nrw = '720px';
export const width_lg = "860px";
export const width_mn = '1000px';
export const width_ipad = "1030px";
export const width_hdr = '1440px';

export const font_sans = '"Source Sans Pro","Helvetica Neue",Arial,Sans-serif';
export const font_serif = 'utopia-std, serif';
export const font_display = 'din-condensed-web,"Source Sans Pro","Helvetica Neue",Arial,Sans-serif';
export const font_helv = '"Helvetica","Source Sans Pro","Helvetica Neue",Arial,Sans-serif';
export const font_thin = '300';
export const font_normal = '400';
export const font_bold = '700';

export const container_standard = '32px';
export const container_standard_half = '16px';

export const spacing_standard = '24px';
export const spacing_standard_half = '12px';

export const box_shadow = "0 0 20px 0 rgba(25,60,86,0.15)";
export const overlay_color = "rgba(25,60,86,0.5)";
