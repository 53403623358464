import React from 'react';
import styled, {css} from 'styled-components';
import * as styles from '../Styles/Stylesheet.js';
import C4 from '../../Media/Audio/Piano.C4.mp3';
import Db4 from '../../Media/Audio/Piano.Db4.mp3';
import D4 from '../../Media/Audio/Piano.D4.mp3';
import Eb4 from '../../Media/Audio/Piano.Eb4.mp3';
import E4 from '../../Media/Audio/Piano.E4.mp3';
import F4 from '../../Media/Audio/Piano.F4.mp3';
import Gb4 from '../../Media/Audio/Piano.Gb4.mp3';
import G4 from '../../Media/Audio/Piano.G4.mp3';
import Ab4 from '../../Media/Audio/Piano.Ab4.mp3';
import A4 from '../../Media/Audio/Piano.A4.mp3';
import Bb4 from '../../Media/Audio/Piano.Bb4.mp3';
import B4 from '../../Media/Audio/Piano.B4.mp3';
import C5 from '../../Media/Audio/Piano.C5.mp3';

export const PianoContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;

  @media only screen and (max-width: ${styles.width_nrw}) {
    flex-direction: column-reverse;
    height: calc(80vh - 69px);
    justify-content: flex-start;
  }
`

const PianoButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  background-color: ${styles.color_white};
  border-top: 1px solid ${styles.color_gry_2};
  border-left: 1px solid ${styles.color_gry_2};
  border-right: 1px solid ${styles.color_gry_2};
  border-bottom: 5px solid ${styles.color_gry_2};
  border-radius: 0 0 8px 8px;
  height: 300px;
  cursor: pointer;
  transition: all 250ms;

  :first-of-type {
    border-radius: 5px 0 8px 8px;
  }

  :last-of-type {
    border-radius: 0 5px 8px 8px;
  }

  ${props => props.pressed && css`
    margin-top: 4px;
    height: 296px;
    border-bottom: 1px solid ${styles.color_gry_2};
    background-color: ${styles.color_gry_1};
    transition: all 250ms;
  `}

  ${props => props.sharp && css`
    width: 50%;
    height: 200px;
    z-index: 1;
    margin-left: calc(((100% + 2px) / -16) / 2);
    margin-right: calc(((100% + 2px) / -16) / 2);
    border-radius: 5px 5px 8px 8px;
    margin-top: -5px;
    background-color: ${styles.color_primary};
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 5px solid ${styles.color_primary_dark};

    ${props => props.pressed && css`
      margin-top: -1px;
      height: 196px;
      border-bottom: 1px solid ${styles.color_secondary};
      background-color: ${styles.color_secondary};
      transition: all 250ms;
    `}
  `}

  @media only screen and (max-width: ${styles.width_nrw}) {
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    border-right: 5px solid ${styles.color_gry_2};
    border-bottom: 1px solid ${styles.color_gry_2};
    border-radius: 0 8px 8px 0;
    width: 100%;
    height: 100%;
    margin-top: 0;

    :first-of-type {
      border-radius: 5px 8px 8px 0;
    }

    :last-of-type {
      border-radius: 0 8px 8px 5px;
    }

    ${props => props.pressed && css`
      margin-left: 4px;
      width: calc(100% - 4px);
      border-right: 1px solid ${styles.color_gry_2};
      background-color: ${styles.color_gry_1};
      transition: all 250ms;
    `}

    ${props => props.sharp && css`
      height: 75%;
      width: 50%;
      border-right: 5px solid ${styles.color_primary_dark};
      border-bottom: 0;
      margin-left: -5px;
      margin-right: 0;
      border-radius: 5px 8px 8px 5px;
      margin-top: calc((((80vh - 69px) + 2px) / -10.777) / 2);
      margin-bottom: calc((((80vh - 69px) + 2px) / -10.777) / 2);

      ${props => props.pressed && css`
        margin-left: -1px;
        width: calc(50% - 4px);
        border-right: 1px solid ${styles.color_secondary};
        background-color: ${styles.color_secondary};
        transition: all 250ms;
      `}
    `}
  }

  ${props => props.invisible && css`
    opacity: 0;
    pointer-events: none;
  `}
`

const NoteName = styled.div`
  padding: 0 0 ${styles.spacing_standard} 0;
  font-family: ${styles.font_display};
  font-size: 18px;
  font-weight: ${styles.font_bold};
  color: ${styles.color_primary};
  line-height: 1;
  user-select: none;

  ${props => props.sharp && css`
    color: ${styles.color_white};
  `}

  @media only screen and (max-width: ${styles.width_nrw}) {
    transform: rotate(-90deg);
  }
`

const NoteKey = styled.div`
  padding: ${styles.spacing_standard} 0 0 0;
  font-family: ${styles.font_display};
  font-size: 16px;
  font-weight: ${styles.font_bold};
  color: ${styles.color_primary};
  line-height: 1;
  opacity: 0.5;
  user-select: none;

  ${props => props.sharp && css`
    color: ${styles.color_white};
    padding: calc(${styles.spacing_standard} + 5px) 0 0 0;
  `}

  @media only screen and (max-width: ${styles.width_nrw}) {
    display: none;
  }
`

const PianoElement = styled.audio`
  display: none;
`

export class Piano extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      C4: false,
      Db4: false,
      D4: false,
      Eb4: false,
      E4: false,
      F4: false,
      Gb4: false,
      G4: false,
      Ab4: false,
      A4: false,
      Bb4: false,
      B4: false,
      C5: false,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('keyup', this.handleKeyUp);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  playAudio(note, time) {
    var audioElement = document.getElementById(note)
    audioElement.currentTime = time;
    audioElement.play();
  }

  // Handles key presses
  handleKeyDown = (event) => {
    let { key } = event
    if (event.repeat) { return }

    if (key === 'a') {
      event.preventDefault()
      this.playAudio('C4', 0.8)
    } else if (key === 'w') {
      event.preventDefault()
      this.playAudio('Db4', 0.8)
    } else if (key === 's') {
      event.preventDefault()
      this.playAudio('D4', 0.8)
    } else if (key === 'e') {
      event.preventDefault()
      this.playAudio('Eb4', 0.8)
    } else if (key === 'd') {
      event.preventDefault()
      this.playAudio('E4', 0.8)
    } else if (key === 'f') {
      event.preventDefault()
      this.playAudio('F4', 0.8)
    } else if (key === 't') {
      event.preventDefault()
      this.playAudio('Gb4', 0.5)
    } else if (key === 'g') {
      event.preventDefault()
      this.playAudio('G4', 0.8)
    } else if (key === 'y') {
      event.preventDefault()
      this.playAudio('Ab4', 0.8)
    } else if (key === 'h') {
      event.preventDefault()
      this.playAudio('A4', 0.3)
    } else if (key === 'u') {
      event.preventDefault()
      this.playAudio('Bb4', 0.5)
    } else if (key === 'j') {
      event.preventDefault()
      this.playAudio('B4', 0.5)
    } else if (key === 'k') {
      event.preventDefault()
      this.playAudio('C5', 0.3)
    }

    if (key === 'a') {
      this.setState({
        C4: true,
      })
    }
    if (key === 'w') {
      this.setState({
        Db4: true,
      })
    }
    if (key === 's') {
      this.setState({
        D4: true,
      })
    }
    if (key === 'e') {
      this.setState({
        Eb4: true,
      })
    }
    if (key === 'd') {
      this.setState({
        E4: true,
      })
    }
    if (key === 'f') {
      this.setState({
        F4: true,
      })
    }
    if (key === 't') {
      this.setState({
        Gb4: true,
      })
    }
    if (key === 'g') {
      this.setState({
        G4: true,
      })
    }
    if (key === 'y') {
      this.setState({
        Ab4: true,
      })
    }
    if (key === 'h') {
      this.setState({
        A4: true,
      })
    }
    if (key === 'u') {
      this.setState({
        Bb4: true,
      })
    }
    if (key === 'j') {
      this.setState({
        B4: true,
      })
    }
    if (key === 'k') {
      this.setState({
        C5: true,
      })
    }
  };

  // Handles key presses
  handleKeyUp = (event) => {
    let { key } = event

    if (key === 'a') {
      this.setState({
        C4: false,
      })
    }
    if (key === 'w') {
      this.setState({
        Db4: false,
      })
    }
    if (key === 's') {
      this.setState({
        D4: false,
      })
    }
    if (key === 'e') {
      this.setState({
        Eb4: false,
      })
    }
    if (key === 'd') {
      this.setState({
        E4: false,
      })
    }
    if (key === 'f') {
      this.setState({
        F4: false,
      })
    }
    if (key === 't') {
      this.setState({
        Gb4: false,
      })
    }
    if (key === 'g') {
      this.setState({
        G4: false,
      })
    }
    if (key === 'y') {
      this.setState({
        Ab4: false,
      })
    }
    if (key === 'h') {
      this.setState({
        A4: false,
      })
    }
    if (key === 'u') {
      this.setState({
        Bb4: false,
      })
    }
    if (key === 'j') {
      this.setState({
        B4: false,
      })
    }
    if (key === 'k') {
      this.setState({
        C5: false,
      })
    }
  };

  // Handles key presses
  handleMouseDown(event, note, time) {
    var audioElement = document.getElementById(note)
    audioElement.currentTime = time;
    audioElement.play();

    this.setState({
      [note]: true
    })
  };

  // Handles key presses
  handleMouseUp(event, note) {
    this.setState({
      [note]: false
    })
  };


  render() {
    return (
      <PianoContainer>
        <PianoButton pressed={this.state.C4} onMouseDown={(e) => {this.handleMouseDown(e, 'C4', 0.8)} } onMouseUp={(e) => {this.handleMouseUp(e, 'C4')}}>
          <NoteKey>A</NoteKey>
          <NoteName>C</NoteName>
        </PianoButton>
        <PianoButton sharp="true" pressed={this.state.Db4} onMouseDown={(e) => {this.handleMouseDown(e, 'Db4', 0.8)} } onMouseUp={(e) => {this.handleMouseUp(e, 'Db4')}}>
          <NoteKey sharp="true">W</NoteKey>
          <NoteName sharp="true">D<sup>#</sup></NoteName>
        </PianoButton>
        <PianoButton pressed={this.state.D4} onMouseDown={(e) => {this.handleMouseDown(e, 'D4', 0.8)} } onMouseUp={(e) => {this.handleMouseUp(e, 'D4')}}>
          <NoteKey>S</NoteKey>
          <NoteName>D</NoteName>
        </PianoButton>
        <PianoButton sharp="true" pressed={this.state.Eb4} onMouseDown={(e) => {this.handleMouseDown(e, 'Eb4', 0.8)} } onMouseUp={(e) => {this.handleMouseUp(e, 'Eb4')}}>
          <NoteKey sharp="true">E</NoteKey>
          <NoteName sharp="true">E<sup>#</sup></NoteName>
        </PianoButton>
        <PianoButton pressed={this.state.E4} onMouseDown={(e) => {this.handleMouseDown(e, 'E4', 0.8)} } onMouseUp={(e) => {this.handleMouseUp(e, 'E4')}}>
          <NoteKey>D</NoteKey>
          <NoteName>E</NoteName>
        </PianoButton>
        <PianoButton sharp="true" invisible="true"></PianoButton>
        <PianoButton pressed={this.state.F4} onMouseDown={(e) => {this.handleMouseDown(e, 'F4', 0.8)} } onMouseUp={(e) => {this.handleMouseUp(e, 'F4')}}>
          <NoteKey>F</NoteKey>
          <NoteName>F</NoteName>
        </PianoButton>
        <PianoButton sharp="true" pressed={this.state.Gb4} onMouseDown={(e) => {this.handleMouseDown(e, 'Gb4', 0.5)} } onMouseUp={(e) => {this.handleMouseUp(e, 'Gb4')}}>
          <NoteKey sharp="true">T</NoteKey>
          <NoteName sharp="true">G<sup>#</sup></NoteName>
        </PianoButton>
        <PianoButton pressed={this.state.G4} onMouseDown={(e) => {this.handleMouseDown(e, 'G4', 0.8)} } onMouseUp={(e) => {this.handleMouseUp(e, 'G4')}}>
          <NoteKey>G</NoteKey>
          <NoteName>G</NoteName>
        </PianoButton>
        <PianoButton sharp="true" pressed={this.state.Ab4} onMouseDown={(e) => {this.handleMouseDown(e, 'Ab4', 0.8)} } onMouseUp={(e) => {this.handleMouseUp(e, 'Ab4')}}>
          <NoteKey sharp="true">Y</NoteKey>
          <NoteName sharp="true">A<sup>#</sup></NoteName>
        </PianoButton>
        <PianoButton pressed={this.state.A4} onMouseDown={(e) => {this.handleMouseDown(e, 'A4', 0.3)} } onMouseUp={(e) => {this.handleMouseUp(e, 'A4')}}>
          <NoteKey>H</NoteKey>
          <NoteName>A</NoteName>
        </PianoButton>
        <PianoButton sharp="true" pressed={this.state.Bb4} onMouseDown={(e) => {this.handleMouseDown(e, 'Bb4', 0.5)} } onMouseUp={(e) => {this.handleMouseUp(e, 'Bb4')}}>
          <NoteKey sharp="true">U</NoteKey>
          <NoteName sharp="true">B<sup>#</sup></NoteName>
        </PianoButton>
        <PianoButton pressed={this.state.B4} onMouseDown={(e) => {this.handleMouseDown(e, 'B4', 0.5)} } onMouseUp={(e) => {this.handleMouseUp(e, 'B4')}}>
          <NoteKey>J</NoteKey>
          <NoteName>B</NoteName>
        </PianoButton>
        <PianoButton sharp="true" invisible="true"></PianoButton>
        <PianoButton pressed={this.state.C5} onMouseDown={(e) => {this.handleMouseDown(e, 'C5', 0.3)} } onMouseUp={(e) => {this.handleMouseUp(e, 'C5')}}>
          <NoteKey>K</NoteKey>
          <NoteName>C</NoteName>
        </PianoButton>
        <PianoElement id="C4" src={C4}></PianoElement>
        <PianoElement id="Db4" src={Db4}></PianoElement>
        <PianoElement id="D4" src={D4}></PianoElement>
        <PianoElement id="Eb4" src={Eb4}></PianoElement>
        <PianoElement id="E4" src={E4}></PianoElement>
        <PianoElement id="F4" src={F4}></PianoElement>
        <PianoElement id="Gb4" src={Gb4}></PianoElement>
        <PianoElement id="G4" src={G4}></PianoElement>
        <PianoElement id="Ab4" src={Ab4}></PianoElement>
        <PianoElement id="A4" src={A4}></PianoElement>
        <PianoElement id="Bb4" src={Bb4}></PianoElement>
        <PianoElement id="B4" src={B4}></PianoElement>
        <PianoElement id="C5" src={C5}></PianoElement>
      </PianoContainer>
    );
  }
}
