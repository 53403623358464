import React from 'react';
import styled, { css } from 'styled-components';
import { FeatureHeader, Input } from '../StyleGuide/Atoms';
import * as styles from '../StyleGuide/Styles/Stylesheet.js';
import * as animations from '../StyleGuide/Styles/Animations.js';
import * as Atom from '../StyleGuide/Atoms';
import * as Organism from '../StyleGuide/Organisms';

// Container for entire page

const CharacterSheetsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${styles.color_gry_1};
`

const AddPlayerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid ${styles.color_gry_2};
  background-color: ${styles.color_white};
`

const AddPlayerWrapper = styled.div`
  width: 100%;
  max-width: ${styles.width_nrw};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: ${styles.spacing_standard};

  @media only screen and (max-width: ${styles.width_sm}) {
    flex-direction: column;
  }
`

const AddPlayerButton = styled.div`
  margin: 0 0 0 ${styles.spacing_standard};
  flex-shrink: 0;

  @media only screen and (max-width: ${styles.width_sm}) {
    margin: 0 0 ${styles.spacing_standard} 0;
    width: 100%;
  }
`

const PlayersListWrapper = styled.div`
  width: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${styles.color_gry_1};
  padding: ${styles.container_standard};
`

const NoRepeatsError = styled.div`
  display: none;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${styles.color_red};
  padding: ${styles.spacing_standard};
  color: ${styles.color_white};
  font-family: Source Sans Pro, Sans-serif;
  font-size: 16px;
  font-weight: ${styles.font_bold};
  text-align: center;
  line-height: 1;
  opacity: 0;

  ${props => props.shown && css `
    display: flex;
    ${animations.fade_in};
  `}

`

class CharacterSheets extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      playerName: '',
      noRepeats: false,
      playerList: []
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.addEventListener('keydown', this.handleKeyDown);
  }


  // Handles key presses
  handleKeyDown = (event) => {
    if (event.repeat) { return }

    if (event.keyCode === 13) {
      this.addCard()
    }
  };

  updateName(e) {
    this.setState({
      playerName: e.target.value
    })
  }

  addCard() {
    const playerName = this.state.playerName.toLowerCase()
    const playerList = this.state.playerList
    var noRepeats = true
    var joined = this.state.playerList.concat({name: playerName});

    for(var i = 0; i < playerList.length; i++) {
      if (playerList[i].name === playerName) {
        noRepeats = false
        break;
      } else {
        noRepeats = true
      }
    }

    if (playerName !== '' && noRepeats) {
      this.setState({ playerList: joined })
    }

    if (!noRepeats) {
      this.showRepeatError()
    }

    this.updateName('');
  }

  showRepeatError() {
    this.setState({
      noRepeats: true
    }, () => {
      setTimeout(() => {
        this.setState({
          noRepeats: false
        })
      }, 3000);
    })
  }

  removeBlock(name) {
    const playerList = this.state.playerList
    const newPlayerList = playerList.filter((person) => person.name !== name);
    this.setState({playerList: newPlayerList}, () => console.log(this.state.playerList));
  }

  render() {

    const { playerList, playerName, noRepeats } = this.state

    return (
      <CharacterSheetsContainer>
        <FeatureHeader title="Character Sheets" text="This feature is used for The Binding of Isaac card game. Players are allowed 1 minute per round +10 seconds for each item they own. Add players in turn order, or create your own player sheet to keep track of your stats, monster health during combat, and numbers significant to you."> </FeatureHeader>

        <AddPlayerContainer>
          <AddPlayerWrapper>
            <Input value={playerName} placeholder="Player name" onChange={(value) => {this.updateName(value)}} />
            <AddPlayerButton onClick={() => {this.addCard()}}>
              <Atom.ButtonFilled text="Add Player" />
            </AddPlayerButton>
          </AddPlayerWrapper>
        </AddPlayerContainer>

        <NoRepeatsError shown={noRepeats}>Player names cannot be repeated</NoRepeatsError>

        <PlayersListWrapper>

        {playerList.map((i, index) => {
            return (
              <Organism.CharacterSheet
              removeBlock={(name) => {this.removeBlock(playerList[index].name)}}
              name={playerList[index].name}
              key={playerList[index].name}
              id={index} />
            )
          }
        )}

        </PlayersListWrapper>

      </CharacterSheetsContainer>
    );
  }
}

export default CharacterSheets;
