import React, { useState, useEffect} from 'react';
import './hate-list.css';
import firebase from '../firebase';
import { doc } from 'firebase/firestore';

const HateList = () => {
  const [hateList, setHateList] = useState([]);

  useEffect(() => {
    const fetchData = async() => {
      const db = firebase.firestore()
      const data = await db.collection('daniels-hate-list').get()
      setHateList(data.map(item => item.data()));
    }
    fetchData();
  }, [])
  
  return (
    <div>
      <div className="hate-hero">
        <div className="hate-herocopy">
          <h1 className="hate-header fadeIn animatedheader">
            Daniel's Hate: <span className="hate-red">Concentrated</span>
          </h1>
          <p className="hate-copy">
            <span className="bodycopy fadeIn animatedsubhead">Daniel hates many things...</span><span className="bodycopy fadeIn animatedbody"> so many things in fact that I decided to start writing them down.</span><br /><span className="bodycopy fadeIn animatedbodyextended">This is the concentrated list of Daniel's hatred.</span>
          </p>
        </div>
      </div>
      {hateList}
      <div className="hate-list">
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">1</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Long Island Iced Teas</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">2</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Sonic</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">3</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Chuy's</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">4</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Crocs</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">5</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Fedoras</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">6</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Kathy Griffin</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">7</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">El Chico</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">8</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Mustard</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">9</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Food truck mac'n'cheese (Mr Nice Guys)</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">10</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Tony Hawk Downhill Jam</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">11</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Feet</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">12</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Hideaway leftovers</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">13</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Moles (the animal)</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">14</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Ron's french fries</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">15</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Casinos</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">16</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Man buns</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">17</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Stand alone mustaches</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">18</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When girls overstay their welcome</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">19</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Little Debbie snacks</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">20</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Being called a hipster</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">21</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Jnco jeans</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">22</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Miles Teller</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">23</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">PG-13 horror movies</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">24</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When people pronounce Gif with a J</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">25</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">The word "thistle", just the word, not the plant</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">26</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When guys have long fingernails</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">27</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When people hang their feet out of car windows</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">28</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Spaghetti Warehouse</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">29</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Monopoly</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">30</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Bacardi 151</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">31</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">IBC ATMS</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">32</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">"Damn Daniel" (he secretly loves it)</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">33</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Watching cats kill things</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">34</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Every season of Glee after season 2</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">35</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Any question Tristan asks while drunk</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">36</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Every TV show on MTV, except Ridiculousness</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">37</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">The ending of Dexter (otherwise great series)</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">38</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Fall Out Boy</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">39</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">People who still write checks</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">40</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Receiving a water with lemon and the seed of the lemon sinks to the bottom of the drink and you have to carefully sip the last bit of water in fear that you'll swallow the seed</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">41</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Rhema Bible Church</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">42</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Evangelical Churches</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">43</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Open mouth sneezing</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">44</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Fries from Oktoberfest</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">45</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Toe Thumbs (Example: Megan Fox)</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">46</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">People who don't respect the rules of shotgun</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">47</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">People who wear T-Shirts under their blazers</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">48</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When people try to have conversations while in the bathroom</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">49</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Excessive PDA</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">50</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When people read over your shoulder while you're trying to text</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">51</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Gladiator Birkenstocks</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">52</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Chick-fil-a drive-thrus</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">53</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Printers</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">54</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Comfort Inn fitness centers</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">55</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Bumper stickers</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">56</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">People who follow every single rule in the rulebook down to a T at their job</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">57</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Cars with doors or bumpers that are different colors from the rest of the car</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">58</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Getting electrocuted</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">59</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Playing Hammer Tag with Lauren</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">60</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Bachelorette and Bachelor</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">61</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When people smack their food</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">62</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">People who blow their nose obnoxiously</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">63</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">The phrase "All Lives Matter"</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">64</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">The ceiling at Saturn Room</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">65</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Swedish Fish</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">66</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When people take their shirts off in a way that they end up inside out</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">67</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Taco Bell</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">68</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Chacos</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">69</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Cotton clothing</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">70</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Getting new wallets</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">71</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">People who can't draw dicks correctly</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">72</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Cars without tinted windows</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">73</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Red cars that aren't sports cars</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">74</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When people eat IHOP during the day while everything else is still open</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">75</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Pineapples on pizza</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">76</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">People who leave their dog stuck in a car on hot days</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">77</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Eating with chopsticks</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">78</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Salt and vinegar chips</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">79</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">THOSE FLIP FLOPS WITH THE THICK SOLES</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">80</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Spray-painted rims</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">81</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">The Bisquick blueberry pancakes song</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">82</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Poor handshakes</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">83</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Music with cuss words edited out</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">84</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When you hold the door open for people and they don't say "Thank you."</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">85</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">F5 (the beer)</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">86</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Mist fans</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">87</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When people pronounce QDoba as "kwuh-doba"</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">88</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">The name Carlisle</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">89</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Taking medicine while drinking beer</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">90</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">The phrase "That's the cat's pajamas"</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">91</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Hanging pictures</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">92</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Every outfit that Cam Newton wears</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">93</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Racists</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">94</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Men named Ashley</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">95</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Foam pits at trampoline gyms</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">96</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When people tell you something is unhealthy</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">97</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Watching white drunk college kids dance</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">98</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Mom jeans</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">99</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">106.9 KHitz</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">100</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Girls with synced up periods</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">101</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Walmart</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">102</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Frugal people</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">103</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Bagged wine</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">104</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">People who rev their engines while in traffic</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">105</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">The pull-out method</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">106</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Fox News</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">107</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Tomi Lahren</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">108</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Taking care of drunk people while drunk</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">109</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Bowling shoes</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">110</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When someone parks one tire on the curb</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">111</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Standard definition DVDs</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">112</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Girls who smoke cigarettes</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">113</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When someone watches you eat</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">114</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Couples who share Facebook accounts</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">115</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When Carl claims to not know what Digornio is</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">116</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When people put ketchup on their fries instead of on the side</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">117</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Black boots over white jeans</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">118</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When people play music outloud in areas where headphones are more preferred (public areas)</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">119</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When people MICROWAVE their toaster strudel</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">120</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Those fucking antlers people put on their cars</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">121</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Getting 99 cents back as change</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">122</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Jean jackets worn with jeans</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">123</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">The ends of bread (also known as butt bread)</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">124</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">People who expect invites to parties they know they can't attend</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">125</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Back seat drivers</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">126</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When people say everything happens for a reason</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">127</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When you clean up after a party and there are open beers that are still full</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">128</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When people have their selfies set as their phone backgrounds</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">129</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When people preface questions with "can I ask you a question?"</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">130</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Christmas music!!!!</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">131</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When people wear socks with sandals</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">132</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">One word text replies</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">133</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Avril Lavigne's makeup</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">134</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When people change their voice demeanor based on the ethnicity of people they are talking to</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">135</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When you have to use a public bathroom minutes after the pervious person just took a gigantic shit and made the whole room smell like a bag of assholes</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">136</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When you have a conversation with somebody and they make eye contact with you the ENTIRE time</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">137</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">People who chew like a god damn horse</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">138</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Everything about icing</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">139</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">The people who invented icing</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">140</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Gel pens</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">141</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">The way Adam holds his beer</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">142</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Plaid socks</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">143</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">People who eat just marshmellows (they are acceptable in hot chocolate, in cereal, on a s'more, etc.)</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">144</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">The Fayetteville Bank</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">145</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">The phrase "It's been real and it's been fun, but it hasn't been real fun"</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">146</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">People who constantly ask for a bite of your food</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">147</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When you're talking to a girl and another person thinks you are being a creep so they come to their "rescue"</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">148</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Bitches who dance barefoot in clubs</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">149</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">People who put poop emoji pillows on their dash board</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">150</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When people order water and get a shit load of lemons instead of just ordering lemonade</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">151</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Josh 'Schmitty' Schmitstinstein, the host of QuipLash 2</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">152</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When people bring their pets into retail stores</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">153</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Red shorts with orange shorts</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">154</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Neighborhoods without sidewalks</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">155</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When see a girl you meet online in person and she's 200lbs heavier</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">156</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When people interrupt you while you're talking</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">157</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">That one girl's hand in front of my face during the Chance the Rapper concert</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">158</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">People who pee without using their hands at urinals</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">159</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">People who park next to you in an empty parking lot</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">160</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Fireball whiskey</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">161</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Having to pee during long road trips</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">162</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Non-alcoholic beer</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">163</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Going to dinner with your sibling and the server thing you're a couple</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">164</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">People who pick one number higher than the previous person on The Price is Right</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">165</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When people owe you money and instead of just paying you back, they pay you back in small increments or with things other than money</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">166</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Couples who spend every second of every day together</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">167</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">People who take 5 hour naps</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">168</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Going to the dentist</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">169</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">People who take out the trash without replacing the bag</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">170</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">People who pour milk before cereal</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">171</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">People who shop without a shirt</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">172</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Pulling up to the gas pump on the wrong side</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">173</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Showing up at a bar 30 minutes before it closes and paying the cover charge</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">174</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Selfie sticks</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">175</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Puking in a moving vehicle</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">176</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Car passengers who try to hoard the aux cord</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">177</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Smart dumb people</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">178</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">People who put noses on their smileys in text messages</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">179</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Despacito</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">180</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When you bite your cheek and you know a cold sore is giong to show up 3 days later</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">181</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When your card gets declined but you know it has money</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">182</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">People who think they deserve free stuff for being a customer for a long time</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">183</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Getting one single grocery item bagged</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">184</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">People who take pictures of all their meals</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">185</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Wonder Bread</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">186</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Having to shit in a really quiet house because the plops seem significantly louder</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">187</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Random boners in public</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">188</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When people ask if the girl you're with is your girlfriend and you have to awkwardly say no</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">189</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">The sound of 2 cardboard boxes scrapping together</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">190</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When the TV volume is left on an odd number</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">191</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When someone offers you food that you know is disgusting but it's too impolite to say no so you eat it anyway and suffer until it's all down your throat</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">192</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When people ask questions they can just Google</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">193</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">When people show up to a restaurant or business one minute before closing</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">194</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Fat people who insist on giving fitness advice</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">195</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Being sick on your day off</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">196</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Women who expect you to pay for everything 100% of the time</p>
          </div>
        </div>
        <div className="hate-rows">
          <div className="hate-numbers">
            <p className="numberlist">197</p>
          </div>
          <div className="hate-words">
            <p className="hate-copy">Bottom-shelf liquor</p>
          </div>
        </div>
        <div className="hate-final-row" className="fadeIn wow animated">
          <div className="hate-numbers">
            <p className="numberlist"></p>
          </div>
          <div className="hate-words">
            <p className="more-hate"><em>...more to come I'm sure</em></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HateList;
