import './homepage.scss';
import React, { useState, useEffect } from 'react';
import {
  Link
} from "react-router-dom";
import { LogoAnimation } from '../../StyleGuide/Organisms/index';

const Homepage = () => {
  const [slideFirstColor, setSlideFirstColor] = useState(false)
  const [slideSecondColor, setSlideSecondColor] = useState(false)
  const [slideThirdColor, setSlideThirdColor] = useState(false)
  const [slideFourthColor, setSlideFourthColor] = useState(false)
  const [fadeInText, setFadeInText] = useState(false)
  const [fadeOutText, setFadeOutText] = useState(false)
  const [contentWrapper, setContentWrapper] = useState(false)
  const [fadeInLinks, setFadeInLinks] = useState(false)
  const [disableTransitions, setDisableTransitions] = useState(false)

	useEffect(() => {

    setTimeout(() => {
      setSlideFirstColor(true);
    }, 1800);

    setTimeout(() => {
      setSlideSecondColor(true);
    }, 2100);

    setTimeout(() => {
      setSlideThirdColor(true);
    }, 2400);

    setTimeout(() => {
      setSlideFourthColor(true);
    }, 2700);

    setTimeout(() => {
      setFadeInText(true);
    }, 3500);

    setTimeout(() => {
      setFadeOutText(true);
    }, 5500);

    setTimeout(() => {
      setContentWrapper(true);
    }, 5800);

    setTimeout(() => {
      setFadeInLinks(true);
    }, 7500);

    setTimeout(() => {
      setDisableTransitions(true);
    }, 5800);

	})

  return (
    <div className="homepage-container">
      {contentWrapper ? <div className={`final-content-wrapper`}>
          <LogoAnimation />
        <div className={`homepage-content-wrapper${fadeInLinks ? ' fade' : ''}`}>
          <div className="homepage-line"></div>
          <div className="homepage-links-wrapper">
            <Link className="homepage-link" to="/resume">Resume</Link>
            <div className="homepage-bullet">&#8226;</div>
            <Link className="homepage-link" to="/style-guide">Style Guide</Link>
            <div className="homepage-bullet">&#8226;</div>
            <Link className="homepage-link" to="/contact">Contact</Link>
          </div>
        </div>
      </div> : null}
      <div className="first-text">You have a no Winn situation</div>
      <div className={`homepage-first-container${slideFirstColor ? ' slide' : ''}${disableTransitions ? ' disable-transitions' : ''}`}>
        <div className={`homepage-first-color${slideFirstColor ? ' slide' : ''}`}></div>
      </div>
      <div className={`homepage-second-container${slideSecondColor ? ' slide' : ''}${disableTransitions ? ' disable-transitions' : ''}`}>
        <div className={`homepage-second-color${slideSecondColor ? ' slide' : ''}`}></div>
      </div>
      <div className={`homepage-third-container${slideThirdColor ? ' slide' : ''}${disableTransitions ? ' disable-transitions' : ''}`}>
        <div className={`homepage-third-color${slideThirdColor ? ' slide' : ''}`}></div>
      </div>
      <div className={`homepage-fourth-container${slideFourthColor ? ' slide' : ''}${disableTransitions ? ' disable-transitions' : ''}`}>
        <div className={`homepage-fourth-color${slideFourthColor ? ' slide' : ''}`}></div>
      </div>
      <div className={`second-text${fadeInText ? ' fade' : ''}${fadeOutText ? ' fadeout' : ''}`}>Let me fix that</div>
    </div>
  );
  
}

export default Homepage;
