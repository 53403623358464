import React from 'react';
import styled from 'styled-components';
import * as styles from '../Styles/Stylesheet.js';

export const HeaderBarContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${styles.color_accent};
  margin-bottom: ${styles.spacing_standard};
`

const HeaderBarTitle = styled.div`
  padding: 0 ${styles.spacing_standard};
  color: ${styles.color_white};
  font-family: ${styles.font_display};
  line-height: 1.1;
  text-align: center;
  font-size: 22px;
  padding: 60px 0;
`

export class HeaderBar extends React.Component {

  render() {
    return (
      <HeaderBarContainer>
        <HeaderBarTitle>
          {this.props.title}
        </HeaderBarTitle>
      </HeaderBarContainer>
    );
  }
}
