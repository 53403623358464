import React from 'react';
import styled, {css} from 'styled-components';
import * as styles from '../Styles/Stylesheet.js';
import * as Atom from '../Atoms';

const CharacterSheetBlockContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: ${styles.width_sm};
  padding: ${styles.spacing_standard};
  margin: 0 0 ${styles.container_standard} 0;
  background-color: ${styles.color_white};
  box-shadow: ${styles.box_shadow};
`

const DeleteBlock = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  top: -15px;
  left: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${styles.color_red};
  background-color: ${styles.color_white};
  box-shadow: ${styles.box_shadow};
  border-radius: 50%;
  transition: all 250ms;
  cursor: pointer;

  :hover {
    background-color: ${styles.color_gry_1};
  }
`

const TimerDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 0 ${styles.spacing_standard_half} 0;
  color: ${styles.color_accent};
  font-family: Source Sans Pro, Sans-serif;
  font-size: 28px;
  font-weight: ${styles.font_bold};
  line-height: 1;

  @media only screen and (max-width: ${styles.width_sm}) {
    padding: 0 0 8px 0;
  }
`

const NumberButton = styled.div`
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${styles.color_accent};
  font-family: Source Sans Pro, Sans-serif;
  font-size: 28px;
  font-weight: ${styles.font_bold};
  line-height: 1;
  background-color: ${styles.color_gry_1};
  border-radius: 5px;
  cursor: pointer;
  transition: all 250ms;

  :hover {
    background-color: ${styles.color_gry_2};
  }
`

export const NumberButtonIcon = styled.i`
  font-size: 12px;
  text-decoration: none;
  color: ${styles.color_accent};
`

const Number = styled.div`
  padding: 0 ${styles.spacing_standard_half};
  color: ${styles.color_accent};
  font-family: Source Sans Pro, Sans-serif;
  font-size: 28px;
  font-weight: ${styles.font_bold};
  line-height: 0.8;
`

const TimerName = styled.div`
  flex-grow: 1;
  padding: 0 ${styles.spacing_standard_half} 0 0;
  color: ${styles.color_accent};
  font-family: Source Sans Pro, Sans-serif;
  font-size: 28px;
  font-weight: ${styles.font_bold};
  line-height: 1;
  text-transform: capitalize;
`

const TimerTime = styled.div`
  width: 100%;
  padding: 0 0 ${styles.container_standard_half} 0;
  color: ${styles.color_accent};
  font-family: Source Sans Pro, Sans-serif;
  font-size: 62px;
  font-weight: ${styles.font_bold};
  line-height: 0.8;

  ${props => props.timeIsLow && css`
    color: ${styles.color_red};
  `}

  ${props => props.timeIsUp && css`
    display: none;
  `}

  @media only screen and (max-width: ${styles.width_sm}) {
    padding: 0 0 ${styles.spacing_standard_half} 0;
    font-size: 52px;
  }
`

const TimerTimeIsUp = styled.div`
  display: none;
  width: 100%;
  padding: 0 0 ${styles.spacing_standard} 0;
  color: ${styles.color_red};
  font-family: Source Sans Pro, Sans-serif;
  font-size: 32px;
  font-weight: ${styles.font_bold};
  line-height: 1;

  ${props => props.timeIsUp && css`
    display: block;
  `}
`

const TimerButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: ${styles.width_sm}) {
    flex-direction: column;
  }
`

const ButtonWrapper = styled.div`
  width: 50%;
  padding: 0 ${styles.spacing_standard_half} 0 0;

  :last-of-type {
    padding: 0;
  }

  @media only screen and (max-width: ${styles.width_sm}) {
    width: 100%;
    padding: 0 0 ${styles.spacing_standard_half} 0;

    :last-of-type {
      padding: 0;
    }
  }
`

const StatsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${styles.spacing_standard} 0 0 0;

  @media only screen and (max-width: ${styles.width_sm}) {
    padding: ${styles.spacing_standard_half} 0 0 0;
  }
`

const Stats = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${styles.spacing_standard_half} 0;
  color: ${styles.color_accent};
  font-family: Source Sans Pro, Sans-serif;
  font-size: 16px;
  font-weight: ${styles.font_bold};
  line-height: 1;
  text-transform: uppercase;
  border-bottom: 1px solid ${styles.color_gry_2};

  :first-of-type {
    border-top: 1px solid ${styles.color_gry_2};
  }

  @media only screen and (max-width: ${styles.width_sm}) {
    padding: 10px 0;
  }
`

const NumberTrackerWrapper = styled.div`
  width: calc(100% + 16px);
  margin: 0 -8px -8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: ${styles.spacing_standard_half} 0 0;

  @media only screen and (max-width: ${styles.width_sm}) {
    padding: 8px 0 0;
    width: calc(100% + 8px);
    margin: 0 -4px -4px;
  }
`

const NumberTracker = styled.div`
  width: calc(16.66% - 16px);
  height: 40px;
  margin: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${styles.color_accent};
  background-color: ${styles.color_white};
  border: 2px solid ${styles.color_gry_2};
  border-radius: 5px;
  font-family: Source Sans Pro, Sans-serif;
  font-size: 16px;
  font-weight: ${styles.font_bold};
  line-height: 1;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 250ms;

  :hover {
    color: ${styles.color_accent};
    background-color: ${styles.color_gry_2};
  }

  @media only screen and (max-width: ${styles.width_sm}) {
    width: calc(33.33% - 8px);
    margin: 4px;
  }

  ${props => props.active && css`
    color: ${styles.color_white};
    background-color: ${styles.color_primary};
    border: 2px solid ${styles.color_primary};

    :hover {
      color: ${styles.color_white};
      background-color: ${styles.color_primary};
    }
  `}
`

export class CharacterSheet extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: this.props.name,
      time: 60,
      number: 0,
      countingDown: false,
      started: false,
      buttonText: 'start',
      timeIsLow: false,
      timeIsUp: false,
      attack: 1,
      health: 2,
      counters: 0,
      monsterHealth: 0,
      numberTracker: [
        {number: 1, active: false},
        {number: 2, active: false},
        {number: 3, active: false},
        {number: 4, active: false},
        {number: 5, active: false},
        {number: 6, active: false},
      ]
    };
    this.timer = null;
    this.startTimer = this.startTimer.bind(this);
    this.countdown = this.countdown.bind(this);
  }

  countdown(number) {
    const currentTime = this.state.time
    const currentNumber = this.state.number

    if (currentTime <= 11) {
      this.setState({ timeIsLow: true});
    }
    if (currentTime === 0) {
      this.setState({ timeIsUp: true});
    } else {
      this.setState({ time: currentTime - 1, number: currentNumber });
    }
  }

  startTimer() {
    const { started } = this.state;
    if (!started) {
      this.timer = setInterval(this.countdown, 1000);
      this.setState({ started: true, buttonText: 'stop', countingDown: true });
    }
    else {
      clearInterval(this.timer);
      this.setState({ started: false, buttonText: 'start', countingDown: false });
    }
  }

  roundEnd(id) {
    var currentNumber = this.state.number
    var currentTime = this.state.time

    currentTime = (10 * currentNumber) + 60

    clearInterval(this.timer);
    this.setState({ time: currentTime, started: false, buttonText: 'start', timeIsLow: false, timeIsUp: false, countingDown: false });

    if (document.querySelectorAll('.character-sheet').length > 1) {
      if ((id + 1) < document.querySelectorAll('.character-sheet').length) {
        const nextBlock = document.getElementById("player-sheet-" + (id + 1));
        nextBlock.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        const nextBlock = document.getElementById("player-sheet-0");
        nextBlock.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }

  changeNumber(change) {
    var currentNumber = this.state.number
    var oldNumber = this.state.number
    var currentTime = this.state.time
    var countingDown = this.state.countingDown

    if (change === "add") {
      currentNumber = currentNumber + 1
    }
    if (currentNumber > 0) {
      if (change === "subtract") {
        currentNumber = currentNumber - 1
      }
    }

    if (currentNumber > oldNumber) {
      currentTime = 10 + currentTime
    }

    if (!countingDown && currentNumber < oldNumber && currentNumber >= 0) {
      currentTime = currentTime - 10
    }

    this.setState({ time: currentTime, number: currentNumber });
  }

  changeAttack(change) {
    var currentAttack = this.state.attack
    if (change === "add") {
      currentAttack = currentAttack + 1
    }
    if (currentAttack > 0) {
      if (change === "subtract") {
        currentAttack = currentAttack - 1
      }
    }

    this.setState({ attack: currentAttack });
  }

  changeHealth(change) {
    var currentHealth = this.state.health
    if (change === "add") {
      currentHealth = currentHealth + 1
    }
    if (currentHealth > 0) {
      if (change === "subtract") {
        currentHealth = currentHealth - 1
      }
    }

    this.setState({ health: currentHealth });
  }

  changeCounter(change) {
    var currentCounter = this.state.counters
    if (change === "add") {
      currentCounter = currentCounter + 1
    }
    if (currentCounter > 0) {
      if (change === "subtract") {
        currentCounter = currentCounter - 1
      }
    }

    this.setState({ counters: currentCounter });
  }

  changeMonsterHealth(change) {
    var currentMonsterHealth = this.state.monsterHealth
    if (change === "add") {
      currentMonsterHealth = currentMonsterHealth + 1
    }
    if (currentMonsterHealth > 0) {
      if (change === "subtract") {
        currentMonsterHealth = currentMonsterHealth - 1
      }
    }

    this.setState({ monsterHealth: currentMonsterHealth });
  }

  removeBlock(name) {
    this.props.removeBlock(name)
  }

  activeNumber(number) {
    var numberTracker = this.state.numberTracker

    Object.keys(numberTracker).forEach((value) => {
      if (numberTracker[value].number === number) {
        numberTracker[value].active = !numberTracker[value].active
      }
    });

    console.log(numberTracker)

    this.setState({ numberTracker: numberTracker });
  }

  render() {

    const { numberTracker } = this.state

    return (
      <CharacterSheetBlockContainer className="character-sheet" id={'player-sheet-' + this.props.id}>
        <DeleteBlock onClick={() => {this.removeBlock(this.state.name)}}><NumberButtonIcon className="fa fa-times"></NumberButtonIcon></DeleteBlock>
        <TimerDetails>
          <TimerName>
            { this.state.name }
          </TimerName>
          <NumberButton onClick={() => {this.changeNumber('subtract')}}>
            <NumberButtonIcon className="fa fa-minus"></NumberButtonIcon>
          </NumberButton>
          <Number>{this.state.number}</Number>
          <NumberButton onClick={() => {this.changeNumber('add')}}>
            <NumberButtonIcon className="fa fa-plus"></NumberButtonIcon>
          </NumberButton>
        </TimerDetails>
        <TimerTime timeIsLow={this.state.timeIsLow} timeIsUp={this.state.timeIsUp}>
          { this.state.time }
        </TimerTime>
        <TimerTimeIsUp timeIsUp={this.state.timeIsUp}>
          Time's up!
        </TimerTimeIsUp>
        <TimerButtonsWrapper>
          <ButtonWrapper onClick={() => {this.startTimer()}}>
            <Atom.ButtonOutline text={this.state.buttonText} wide />
          </ButtonWrapper>
          <ButtonWrapper onClick={() => {this.roundEnd(this.props.id)}}>
            <Atom.ButtonFilled text="end round" color="red" wide />
          </ButtonWrapper>
        </TimerButtonsWrapper>
        <StatsWrapper>
          <Stats>
            <NumberButton onClick={() => {this.changeAttack('subtract')}}>
              <NumberButtonIcon className="fa fa-minus"></NumberButtonIcon>
            </NumberButton>
            Attack: {this.state.attack}
            <NumberButton onClick={() => {this.changeAttack('add')}}>
              <NumberButtonIcon className="fa fa-plus"></NumberButtonIcon>
            </NumberButton>
          </Stats>
          <Stats>
            <NumberButton onClick={() => {this.changeHealth('subtract')}}>
              <NumberButtonIcon className="fa fa-minus"></NumberButtonIcon>
            </NumberButton>
            Health: {this.state.health}
            <NumberButton onClick={() => {this.changeHealth('add')}}>
              <NumberButtonIcon className="fa fa-plus"></NumberButtonIcon>
            </NumberButton>
          </Stats>
          <Stats>
            <NumberButton onClick={() => {this.changeCounter('subtract')}}>
              <NumberButtonIcon className="fa fa-minus"></NumberButtonIcon>
            </NumberButton>
            Counters: {this.state.counters}
            <NumberButton onClick={() => {this.changeCounter('add')}}>
              <NumberButtonIcon className="fa fa-plus"></NumberButtonIcon>
            </NumberButton>
          </Stats>
          <Stats>
            <NumberButton onClick={() => {this.changeMonsterHealth('subtract')}}>
              <NumberButtonIcon className="fa fa-minus"></NumberButtonIcon>
            </NumberButton>
            Monster Health: {this.state.monsterHealth}
            <NumberButton onClick={() => {this.changeMonsterHealth('add')}}>
              <NumberButtonIcon className="fa fa-plus"></NumberButtonIcon>
            </NumberButton>
          </Stats>
          <NumberTrackerWrapper>
            <NumberTracker active={numberTracker[0].active} onClick={() => {this.activeNumber(1)}}>1</NumberTracker>
            <NumberTracker active={numberTracker[1].active} onClick={() => {this.activeNumber(2)}}>2</NumberTracker>
            <NumberTracker active={numberTracker[2].active} onClick={() => {this.activeNumber(3)}}>3</NumberTracker>
            <NumberTracker active={numberTracker[3].active} onClick={() => {this.activeNumber(4)}}>4</NumberTracker>
            <NumberTracker active={numberTracker[4].active} onClick={() => {this.activeNumber(5)}}>5</NumberTracker>
            <NumberTracker active={numberTracker[5].active} onClick={() => {this.activeNumber(6)}}>6</NumberTracker>
          </NumberTrackerWrapper>
        </StatsWrapper>
      </CharacterSheetBlockContainer>
    );
  }
}
