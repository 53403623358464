import "./textarea.scss"
import React from "react"

const Textarea = ({onChange, value, label, placeholder, error, explainer, reversed, disabled}) =>{

	return (
		<div className={`textarea-wrapper${reversed ? ' reversed' : ''}${error ? ' error' : ''}`}>
			{label ? <label className="textarea-label">{label}</label> : null }
			<textarea component="textarea" type="textarea" value={value} placeholder={placeholder} onChange={onChange} disabled={disabled} className="textarea" />
			{explainer ? <div className="textarea-explainer">{explainer}</div> : null }
		</div>
	)
}
 
export default Textarea

 